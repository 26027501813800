<template >
  <div>
    <b-row align-h="center" align="center">
      <b-col cols="11">
        <br>
        <h5><b-icon variant="info" icon="person-fill"></b-icon><span> {{this.perfil.nombre}} {{this.perfil.apellidoPat}} {{this.perfil.apellidoMat}}</span></h5>        
      </b-col>
    </b-row>
    <b-row  align-h="center" >
      <b-col cols="11">
        <br>
        <h5>Certificado (.cer)</h5>
        <b-form-file v-model="fileCer" id="cer" @change="cargarArchivo" browse-text="Buscar" placeholder="Seleccione un archivo o arrastrelo aquí" accept=".cer,.CER" size="lg"></b-form-file>
        <br><br>
      </b-col>
      <b-col cols="11">
        <h5>Clave Privada (.key)</h5>
        <b-form-file v-model="fileKey" id="key" @change="cargarArchivo" browse-text="Buscar" placeholder="Seleccione un archivo o arrastrelo aquí" accept=".key, .KEY" size="lg"></b-form-file>
        <br><br>
      </b-col>
      <b-col cols="11">
        <h5>Contraseña de clave privada</h5>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="lock"></b-icon>
          </b-input-group-prepend>
          <b-form-input  placeholder="Ingresa tu contraseña" type="password" v-model.trim="passKey"></b-form-input>
          <p style="margin-left:3px;" class="h6"><b-icon  icon="question-circle-fill" variant="info"></b-icon></p>
        </b-input-group>
        <br>
      </b-col>
    </b-row>
    <b-row align-h="center" align="center">
      <b-col cols="11" sm="9" md="8" lg="6" xl="5" style="margin-top:10px; ">
        <b-button pill class="btn-back-purple" size="lg" block @click="enviarSolicitudFirmada()" :disabled="pemCer==null || pemKey==null || passKey==null" variant="info">Firmar Solicitud <b-icon  icon="pen" ></b-icon></b-button>        
        <br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapState} from 'vuex';
  import {enviarSolicitudFirma} from '@/axios-tools'
  
  export default {
    computed: {
      ...mapState({
        perfil: state => state.user.perfil,
        token: state => state.user.token,
        currentService: state => state.user.currentService,
      })

    },
    props:[],
    created() {
     
    },
    components: {
      
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        fileCer:null,
        fileKey:null,
        pemCer: null,
        pemKey: null,
        passKey: null,
        sign:null,
      }
    },
    mounted() {
        
    },
    methods: { 
      cargarArchivo(evt){
        //this.$store.commit("updateIsWorking", true);
        var f = evt.target.files[0];
        var reader = new FileReader();
        //Creamos una refrencia local al objeto this para poder acceder desde las funciones anidadas a los objetos cliente, token .. etc
        var thisObject = this;
        reader.onload = (function() {
          return function(theFile) {
            var binaryData = theFile.target.result;
            // Convertir datos binarios a base 64
            var base64String = window.btoa(binaryData);
            var idForm=evt.target.id;
            if(idForm == 'key' ){
              thisObject.pemKey ="-----BEGIN ENCRYPTED PRIVATE KEY-----\r\n" +
              base64String +
              "-----END ENCRYPTED PRIVATE KEY-----\r\n";
            }else{
              thisObject.pemCer="-----BEGIN CERTIFICATE-----\n" +
              base64String +
              "\n-----END CERTIFICATE-----";
            }
          };
        })(f);
        reader.onerror = function(error){
          alert("error al cargar archivo"+error);
        };
        // Leer en el archivo de imagen como una URL de datos.
        reader.readAsBinaryString(f);
      },
      enviarSolicitudFirmada(){
        this.$store.commit("updateIsWorking", true);//Se quita dentro del axios o en el catch
        const jsrsasign = require('jsrsasign');
        var privateKey ;
        try {
          privateKey = jsrsasign.KEYUTIL.getKeyFromEncryptedPKCS8PEM(this.pemKey, this.passKey);
          var sig = new jsrsasign.crypto.Signature({alg: 'SHA256withRSA'});
          sig.init(privateKey);
          sig.updateString(this.currentService.hashSolicitud);
          this.sign = Buffer.from(sig.sign(), 'hex').toString('base64');
          var firmaDto ={
            certificado: this.pemCer,
            selloDigitalContrib:this.sign,
            hashCadenaOriginal:this.currentService.hashSolicitud,
          }
          var idDto={
            b64Front : null,
            b64Back: null,
            tipoId: null,
            extension:null
          }
          enviarSolicitudFirma(this.token, this.currentService.idUserService, firmaDto, idDto, this.$store, this.$router);
          this.pemKey=null
          this.passKey= null
          this.fileCer=null
          this.fileKey=null
          this.$store.commit("updateIsShowModalFirmaElectronica", false);
        }catch(err) {
          this.$store.commit("updateIsWorking", false);
          this.$store.commit("updateMsjSuccessUser", "La constraseña de la firma electrónica es incorrecta");
        }
        
      },
    },
    validations: {
    }
  }
</script>
<style>
</style>