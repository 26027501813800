<template>
  <b-container fluid>
    <b-row align="center" align-h="center">
		<b-col cols="7">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 404.7 317.3" style="enable-background:new 0 0 404.7 317.3;" xml:space="preserve">

<g id="Background">
</g>
<g id="Layout">
	<path id="_x3C_Group_x3E__238_" class="pt0" d="M74.6,83.9c4.6,14,8.4,28.5,11.4,43.3c3.2,16,5.3,32.5,6.5,49.3
		c0.4,5.3,0.6,10.7,1.4,15.9c0.9,6.4,2.5,12.4,5.8,17.6c0.7,1.1,2.7,5.1,5,9.5c0.4,0.8,0.8,1.6,1.2,2.4c2.6,5,5.2,9.7,5.8,9.6
		c8-1,9-18.2,9-20.9c0-15.3-1-30.2-2.9-44.9c-0.4-3-0.8-6-1.3-9c0-0.1,0-0.1,0-0.2c-1-6.3-2.2-12.7-3-19.1c-0.2-1.3-0.3-2.6-0.4-3.9
		c-0.7-7.5-0.7-15.2,0.9-23.3c1-4.9,2.5-10.1,2.1-14.6c-0.6-6.5-5.2-11.1-8-16.6c-5.1-10-4.6-22.6-7.2-33.8
		c-1.6-6.7-4.3-12.8-6.9-18.9L84.2,4.5c-0.4-0.9-2.3-0.2-2.4,0.9c-1.4,16.4-3.1,32.8-7.6,49.8c-1.2,4.5-2.6,9.1-2.8,13.4
		C71.2,74.1,73,79,74.6,83.9z"/>
	<path id="_x3C_Group_x3E__237_" class="pt1" d="M59.1,115.3c1,3.7,1.1,7.4,1.3,11.1c0.7,13,2.3,26,4.9,38.9
		c0.3,1.4,0.6,2.8,0.9,4.2c1,4.8,2.2,9.6,3.6,14.4c0,0.1,0.1,0.3,0.1,0.4c1.4,4.9,3.6,10.1,11.7,12.9c1.8,0.6,3.4-0.1,4.8-1.5
		c0.6-0.6,1.2-1.3,1.7-2.1c2.2-3.3,3.7-7.8,4.2-9c2.5-5.6,4-11.3,3.2-16.9c-0.9-5.8-4.4-11.4-5.5-17.2c-1-5.1-0.2-10.3,0.9-15.4
		c1.1-5.5,2.4-11,2.1-16.5c-0.5-8.6-5.1-16.9-9.6-25.1c-2.8-5.1-5.7-10.2-8.7-15.3c-4.6-7.9-9.4-15.7-15-23.4
		c-1.7-2.4-4.5-5.1-9.8-5.1c0,9.2,0,18.3,0,27.5c0,1.1,0,2.3,0,3.4c0,5.7,0,11.4,1.8,17C53.6,103.5,57.5,109.3,59.1,115.3z"/>
	<path id="_x3C_Group_x3E__234_" class="pt1" d="M85.6,18.2c0,0.2,0.4,23.4,3.5,49c4.2,34.6,11.2,56.9,20.7,66.5
		c1,1,1.8,2.2,2.5,3.6c2.2,4.3,3.1,10.7,3,19.1c-0.3,14.7-4.1,35.5-11.5,61.7c-0.1,0.4-0.2,0.8-0.3,1.2c0.4,0.8,0.8,1.6,1.2,2.4
		c0.3-1.1,0.6-2.1,0.9-3.2c6-21.5,9.7-39.2,11-53c1.5-15.9,0-26.6-4.7-32.2c-0.2-0.3-0.5-0.5-0.7-0.8
		C89.1,110.4,87.5,19.1,87.5,18.2c0-0.5-0.4-0.8-0.9-0.8C86,17.4,85.6,17.8,85.6,18.2z"/>
	<path id="_x3C_Group_x3E__233_" class="pt0" d="M56.4,65.4c5.7,8.9,11.4,29.9,16.5,53.8c4,18.8,7.6,39.4,10.5,57.2
		c1.1,7,2.1,13.5,3,19.3c0.6-0.6,1.2-1.3,1.7-2.1c-0.9-5.8-1.9-12.2-3-19.1c-1.1-6.9-2.3-14.3-3.7-21.7c-2.1-11.8-4.1-22.4-6.1-32
		c-6.1-29.1-11.8-47.9-17.1-56.2c-0.2-0.4-0.8-0.5-1.3-0.2C56.4,64.5,56.2,65,56.4,65.4z"/>
	<path class="pt2" d="M242.8,50.2c-11.8,4.7-22.8,11.3-32.7,19.3c-12.3,9.8-39.9,26.1-81.1,19.1c-57.3-9.8-120,52-88.3,145.7
		s305.6,107.1,347.9-3.1C428.2,128.3,359.2,3.4,242.8,50.2z"/>
	<path id="_x3C_Group_x3E__236_" class="pt3" d="M243,305.4h-86.6c-10.8,0-19.5-8.7-19.5-19.5V78.8c0-10.8,8.7-19.5,19.5-19.5H243
		c10.8,0,19.5,8.7,19.5,19.5v207.1C262.5,296.7,253.8,305.4,243,305.4z"/>
	<path class="pt4" d="M253.2,310.4H146.1c-7.8,0-14.2-6.4-14.2-14.2V68.5c0-7.8,6.4-14.2,14.2-14.2h107.1c7.8,0,14.2,6.4,14.2,14.2
		v227.7C267.5,304,261.1,310.4,253.2,310.4z M146.1,64.3c-2.4,0-4.3,1.9-4.3,4.3v227.7c0,2.4,1.9,4.3,4.3,4.3h107.1
		c2.4,0,4.3-1.9,4.3-4.3V68.5c0-2.4-1.9-4.3-4.3-4.3H146.1z"/>
	<path id="_x3C_Group_x3E__195_" class="pt5" d="M211,69h-25.4c-2.6,0-4.6-2.1-4.6-4.6v-1.8c0-2.6,2.1-4.6,4.6-4.6H211
		c2.6,0,4.6,2.1,4.6,4.6v1.8C215.7,66.9,213.6,69,211,69z"/>
	<path id="_x3C_Group_x3E__232_" class="pt1" d="M51,132.3c3.4,9.8,3.6,19.7,6.2,29.6c2.3,8.5,6.3,16.9,10.1,25.4
		c8.7,19.3,18,41.1,14.3,60.1c-1,5.4-4.7,9.2-12.5,7.9c-0.5-0.1-1-0.2-1.5-0.3c-0.6-0.1-1.2-0.3-1.9-0.5c-10-2.9-15-11.8-18.3-18.1
		c-3.8-7.2-7.5-14.4-11-21.6c-2.6-5.3-5-10.6-5.4-15.9c-0.3-4.1,0.6-8.1,0.4-12.3c-0.7-14.5-15.1-28.9-16.9-43.6
		c-0.8-6.5,0.9-12.8,0.3-19.4c-0.6-6.3-3.5-12.7-5.6-19.1C4.9,92,3.4,79.3,4.7,67c0.1-1,1.8-1.4,3-0.6c2.7,1.7,4.6,4.3,6.2,6.6
		c6.7,9.8,13.4,19.5,20.1,29.3C40.7,112.1,47.5,122,51,132.3z"/>
	<path id="_x3C_Group_x3E__231_" class="pt0" d="M10.6,76.4c0.2,0.5,21,50.1,40,103.8c23.6,66.6,34.3,109.9,32,129
		c-0.7-0.1-1.4-0.2-2.1-0.3c2.5-18.4-8.5-62.5-31.8-128.3C29.7,127,8.9,77.4,8.7,76.9c-0.2-0.6,0-1.2,0.5-1.3
		C9.7,75.5,10.3,75.8,10.6,76.4z"/>
	<path id="_x3C_Group_x3E__230_" class="pt0" d="M287.2,239.6c2.6-11.6,8.7-21.4,10.4-33.4c1.6-11.3-0.7-24.3,0.5-35.8
		c2-19.6,13.9-34.5,13.6-55c-0.1-8.4-2.3-17.6-2.3-26c0-10.2,3.2-19,8-26.6c0.7-1.1,3.1,0.1,3.4,1.6c1.7,8.4,2.6,16.6,3.5,24.8
		c1.4,13,2.9,26,4.3,39c1.2,10.8,2.4,21.6,0.7,31.3c-1.1,6.6-3.6,12.5-4,19.4c-0.7,11.4,4.1,25,2.1,35.9c-1.6,8.8-7.6,15.3-9.4,24
		c-2.8,12.9,3.5,30.1-2.7,41c-4.1,7.2-14.4,4.7-21.3-4.8C285.4,263,285.1,249.1,287.2,239.6z"/>
	<path id="_x3C_Group_x3E__229_" class="pt1" d="M317.6,240.9c4.5-5.2,10.6-9.4,12.8-15.9c1.9-5.8,0.6-13.3,0.4-20.2
		c-0.9-22.5,9-39.7,19.2-56.4c11.5-18.8,23.3-37.4,38.6-53.9c0.7-0.8,4.3,1.2,4.2,2.3l-2.5,22.9c-1.8,16.5-3.7,33.1-8.2,48.2
		c-8.1,27.2-24.9,49.8-30.4,78.4c-1.8,9.5-2.5,19.6-5.6,28.5c-2.3,6.7-6.3,22-20.5,20.7c-16.9-1.6-17-18.4-17.3-24.4
		C307.6,259,310.7,248.8,317.6,240.9z"/>
	<path id="_x3C_Group_x3E__228_" class="pt1" d="M301.3,276.6c0.4,0,0.7-0.3,0.8-0.8c6.9-37.1,11.4-73.6,15.8-110.8
		c0-0.1,0-0.1,0-0.2v-3.7l0.1-1c0.3-3.3,0.6-6.5,0.6-9.9c1.5-16.7,2.7-36.2,0.6-55.1c-0.1-0.6-0.5-1-0.9-0.9
		c-0.4,0.1-0.7,0.6-0.7,1.2c2,18.6,0.8,38-0.6,54.6c0,0,0,0.1,0,0.1c0,3.4-0.3,6.5-0.6,9.8l-0.1,1c0,0,0,0.1,0,0.1v3.7
		c-4.4,37.2-8.9,73.6-15.8,110.5c-0.1,0.6,0.2,1.1,0.6,1.3C301.2,276.6,301.3,276.6,301.3,276.6z"/>
	<path id="_x3C_Group_x3E__227_" class="pt0" d="M320.6,290.6c0.4,0,0.8-0.3,0.8-0.8c0.4-4,1.4-7.8,2.4-11.5
		c0.9-3.2,1.7-6.5,2.2-9.9c7.5-23.5,16.8-51.4,28.6-79.7c0,0,0,0,0,0c0.3-0.9,0.6-1.7,0.8-2.5c0.2-0.8,0.5-1.6,0.8-2.4
		c2-4.9,4.1-9.7,6.1-14.5c8-19.2,16.3-39,24.8-58.4c0.2-0.4,0-1-0.4-1.2c-0.4-0.2-0.9,0-1.1,0.4c-8.5,19.4-16.8,39.2-24.8,58.4
		c-2,4.8-4,9.7-6.1,14.6c0,0,0,0,0,0c-0.3,0.9-0.6,1.7-0.8,2.5c-0.2,0.8-0.5,1.6-0.8,2.4c-11.8,28.4-21.1,56.3-28.7,79.9
		c0,0.1,0,0.1,0,0.2c-0.4,3.3-1.3,6.4-2.2,9.7c-1,3.8-2.1,7.7-2.5,11.8C319.7,290.1,320,290.6,320.6,290.6
		C320.5,290.6,320.5,290.6,320.6,290.6z"/>
	<g>
		<path class="pt6" d="M249.3,233.4c0,0-9.9,2.1-11.5,3c-1.7,0.9-2.6,3.8-2.6,3.8l2.6,2.1l5.1-3.1c0,0,4.9,1.6,7.4-0.4
			S249.3,233.4,249.3,233.4z"/>
		<path class="pt7" d="M277.1,199c-7.3-2.7-7.7,3.1-7.7,3.1s-1.3,11.6-0.2,21.1l-19.8,10.2l0.9,5.4c0,0,24.8-2.8,27.2-5.6
			c2.2-2.5,4.2-20.6,4.6-24.6l0,0C282.1,208.6,284.3,201.7,277.1,199z"/>
	</g>
	<g>
		<rect x="203.8" y="303" class="pt8" width="76.5" height="8"/>
		<g>
			<rect x="212.4" y="295.1" class="pt8" width="76.5" height="8"/>
			<polygon class="pt9" points="212.4,295.1 288.9,303.1 212.4,303.1 			"/>
			<polygon class="pt10" points="288.9,295.1 288.9,303.1 212.4,295.1 			"/>
			<rect x="219.1" y="295.1" class="pt10" width="2" height="8"/>
			<rect x="229.7" y="295.1" class="pt10" width="2" height="8"/>
			<rect x="241.5" y="295.1" class="pt10" width="2" height="8"/>
			<rect x="252.8" y="295.1" class="pt10" width="2" height="8"/>
			<rect x="265.8" y="295.1" class="pt10" width="2" height="8"/>
			<rect x="278.6" y="295.1" class="pt10" width="2" height="8"/>
		</g>
		<g>
			<rect x="204" y="302.9" class="pt8" width="76.5" height="8"/>
			<polygon class="pt9" points="204,302.9 280.5,310.9 204,310.9 			"/>
			<polygon class="pt10" points="280.5,302.9 280.5,310.9 204,302.9 			"/>
			<rect x="210.7" y="302.9" class="pt10" width="2" height="8"/>
			<rect x="221.3" y="302.9" class="pt10" width="2" height="8"/>
			<rect x="233.1" y="302.9" class="pt10" width="2" height="8"/>
			<rect x="244.3" y="302.9" class="pt10" width="2" height="8"/>
			<rect x="257.4" y="302.9" class="pt10" width="2" height="8"/>
			<rect x="270.2" y="302.9" class="pt10" width="2" height="8"/>
		</g>
	</g>
	
		<ellipse transform="matrix(0.914 -0.4058 0.4058 0.914 -68.9411 109.7186)" class="pt9" cx="224.3" cy="217.4" rx="37.9" ry="37.9"/>
	<circle class="pt10" cx="219.2" cy="218.2" r="37.9"/>
	<path class="pt9" d="M231,248.3c-3.2,1.2-6.6,1.9-10.1,2c-8.6,0.3-16.8-2.7-23.1-8.5c-6.3-5.8-10-13.7-10.3-22.3
		c-0.3-8.6,2.7-16.8,8.5-23.1c5.8-6.3,13.7-10,22.3-10.3c8.6-0.3,16.8,2.7,23.1,8.5c6.3,5.8,10,13.7,10.3,22.3
		c0.3,8.6-2.7,16.8-8.5,23.1C239.8,243.7,235.6,246.6,231,248.3z M208.4,188.5c-4.6,1.7-8.7,4.5-12.1,8.2
		c-5.7,6.2-8.7,14.3-8.4,22.8c0.3,8.5,4,16.3,10.2,22c6.2,5.7,14.3,8.7,22.8,8.4c8.5-0.3,16.3-4,22.1-10.2
		c5.7-6.2,8.7-14.3,8.4-22.8l0.2,0l-0.2,0c-0.3-8.5-4-16.3-10.2-22.1c-6.2-5.7-14.3-8.7-22.8-8.4
		C214.9,186.6,211.6,187.3,208.4,188.5z"/>
	<path id="_x3C_Group_x3E__226_" class="pt4" d="M332.2,311h-31.8c-9.6,0-17.4-11.1-17.4-24.9v-24.9h66.9l-0.3,24.9
		C349.6,299.8,341.8,311,332.2,311z"/>
	<g>
		<rect x="111.4" y="278.7" class="pt8" width="76.5" height="8"/>
		<rect x="103.2" y="286.6" class="pt8" width="76.5" height="8"/>
		<rect x="99.9" y="294.4" class="pt8" width="76.5" height="8"/>
		<rect x="105.4" y="302.3" class="pt8" width="76.5" height="8"/>
		<g>
			<rect x="111.3" y="278.4" class="pt8" width="76.5" height="8"/>
			<polygon class="pt9" points="111.3,278.4 187.9,286.4 111.3,286.4 			"/>
			<polygon class="pt10" points="187.9,278.4 187.9,286.4 111.3,278.4 			"/>
			<rect x="118.1" y="278.4" class="pt10" width="2" height="8"/>
			<rect x="128.7" y="278.4" class="pt10" width="2" height="8"/>
			<rect x="140.5" y="278.4" class="pt10" width="2" height="8"/>
			<rect x="151.7" y="278.4" class="pt10" width="2" height="8"/>
			<rect x="164.8" y="278.4" class="pt10" width="2" height="8"/>
			<rect x="177.5" y="278.4" class="pt10" width="2" height="8"/>
		</g>
		<g>
			<rect x="103.2" y="286.6" class="pt8" width="76.5" height="8"/>
			<polygon class="pt9" points="103.2,286.6 179.8,294.6 103.2,294.6 			"/>
			<polygon class="pt10" points="179.8,286.6 179.8,294.6 103.2,286.6 			"/>
			<rect x="110" y="286.6" class="pt10" width="2" height="8"/>
			<rect x="120.6" y="286.6" class="pt10" width="2" height="8"/>
			<rect x="132.4" y="286.6" class="pt10" width="2" height="8"/>
			<rect x="143.6" y="286.6" class="pt10" width="2" height="8"/>
			<rect x="156.7" y="286.6" class="pt10" width="2" height="8"/>
			<rect x="169.4" y="286.6" class="pt10" width="2" height="8"/>
		</g>
		<g>
			<rect x="99.9" y="294.7" class="pt8" width="76.5" height="8"/>
			<polygon class="pt9" points="99.9,294.7 176.4,302.7 99.9,302.7 			"/>
			<polygon class="pt10" points="176.4,294.7 176.4,302.7 99.9,294.7 			"/>
			<rect x="106.6" y="294.7" class="pt10" width="2" height="8"/>
			<rect x="117.2" y="294.7" class="pt10" width="2" height="8"/>
			<rect x="129.1" y="294.7" class="pt10" width="2" height="8"/>
			<rect x="140.3" y="294.7" class="pt10" width="2" height="8"/>
			<rect x="153.3" y="294.7" class="pt10" width="2" height="8"/>
			<rect x="166.1" y="294.7" class="pt10" width="2" height="8"/>
		</g>
		<g>
			<rect x="105.4" y="302.8" class="pt8" width="76.5" height="8"/>
			<polygon class="pt9" points="105.4,302.8 182,310.8 105.4,310.8 			"/>
			<polygon class="pt10" points="182,302.8 182,310.8 105.4,302.8 			"/>
			<rect x="112.2" y="302.8" class="pt10" width="2" height="8"/>
			<rect x="122.7" y="302.8" class="pt10" width="2" height="8"/>
			<rect x="134.6" y="302.8" class="pt10" width="2" height="8"/>
			<rect x="145.8" y="302.8" class="pt10" width="2" height="8"/>
			<rect x="158.9" y="302.8" class="pt10" width="2" height="8"/>
			<rect x="171.6" y="302.8" class="pt10" width="2" height="8"/>
		</g>
	</g>
	<path class="pt11" d="M278.6,311.9h-15.3c-0.6,0-0.9-0.7-0.5-1.1l7.5-6.8l-0.7-3.2l6-0.7l0.9,3.8L278.6,311.9z"/>
	<polygon class="pt6" points="276.6,303.9 270.4,304 269.4,299.3 275.8,298.7 	"/>
	<path class="pt11" d="M322.5,311.9h-15.3c-0.6,0-0.9-0.8-0.5-1.2l7.5-7.4l-0.7-3.4l6-0.8l0.9,4.1L322.5,311.9z"/>
	<polygon class="pt6" points="320.5,303.3 314.3,303.4 313.6,300 319.5,299.2 	"/>
	<path class="pt12" d="M301.3,223.4l0.4,0.3c0,0,7.1,12.7,6.8,23.6c-0.4,10.9,1.5,19.3,1.5,19.3l12.5,30.7c0,0-4.6,5-9.5,3.5
		l-15.1-28.7L290,249c0,0-10.1,13.8-16.3,18.9c0,0,2.6,6.7,4.9,31c0,0-8.9,2.1-11.1,1.1l-6.8-33.3l19.9-32.8l-2.6-6.6L301.3,223.4z"
		/>
	<path class="pt13" d="M307.2,232.1c0,0-15.9,6.3-27.7,3L268,207.5c0,0-2.5-8.4,3.8-12.8c6.3-4.4,15.7-1.1,21.5,11
		C299.2,217.9,307.2,232.1,307.2,232.1z"/>
	<path class="pt6" d="M247.1,223.4c0,0-10.1-0.5-11.9,0c-1.8,0.5-3.5,3-3.5,3l2,2.7l5.7-1.8c0,0,4.3,2.8,7.2,1.4
		C249.6,227.5,247.1,223.4,247.1,223.4z"/>
	<path class="pt14" d="M264.5,180c0,0-3.3,0.4-4.1-3.3c-0.8-3.6,1.4-5.8,4.1-4.7c0,0-1.2-4.2,3-4c4.3,0.2,5.9,3.5,5.9,3.5
		s4.9-2.7,7.1,2.6c2.2,5.3-2.6,13.7-2.6,13.7l-3.7,2.6L264.5,180z"/>
	<path class="pt15" d="M264,178.3l-1.3,12.6l4.6,0.7l2.2,5.5c0,0,5.5,0.4,6.7-3.3L273,188c0,0,4.4-4.6,0.2-5l-1.8,1.2
		c0,0-2.1-0.6-0.7-3.7C272,177.4,264,178.3,264,178.3z"/>
	<path class="pt13" d="M282.6,197c-6.4-4.4-8.2,1.1-8.2,1.1s-4.2,10.9-5.5,20.4l-21.8,4.9l-0.4,5.4c0,0,24.7,3.5,27.8,1.4
		c2.7-1.8,9.2-18.9,10.6-22.6l0,0C285,207.6,288.9,201.5,282.6,197z"/>
	<polygon class="pt0" points="123,105.1 119,105.1 119,101.2 116.9,101.2 116.9,105.1 112.9,105.1 112.9,107.3 116.9,107.3 
		116.9,111.2 119,111.2 119,107.3 123,107.3 	"/>
	<polygon class="pt0" points="355.7,91.7 354.1,90.1 349.9,94.3 345.6,90.1 344,91.7 348.2,96 344,100.2 345.6,101.8 349.9,97.6 
		354.1,101.8 355.7,100.2 351.5,96 	"/>
	<polygon class="pt3" points="298,62.1 293,62.1 293,57.1 290.3,57.1 290.3,62.1 285.3,62.1 285.3,64.8 290.3,64.8 290.3,69.8 
		293,69.8 293,64.8 298,64.8 	"/>
	<path class="pt6" d="M62.8,227.1v5.6l-2.2,4.2l-0.4-8.7c0,0-2.2-1.9-1.3-4.2C59.9,221.6,62.8,227.1,62.8,227.1z"/>
	<path class="pt13" d="M55.7,182.5c-7.1,0.4-1.5,7.7-1.5,7.7s6,8.9,12.5,14.8L59,224l3.8,3.2c0,0,15.5-16.8,15.6-20.2
		c0.1-3-11.9-17.9-14-20.9l0,0C64.4,186,60.7,182.2,55.7,182.5z"/>
	<path class="pt16" d="M140.7,194.1c0,0,6.2-8.5,12.6-7.5l3.7,1.6c0,0-0.4,2.3-2.7,1.9c-2.3-0.4-3.9,0.4-3.9,0.4l-4,3.9L140.7,194.1
		z"/>
	<path class="pt11" d="M54.7,302.7l5.7,0.1c0.2,0.2,0.5-0.1,0.9-0.1l7.2,6.9c0.4,0.4,0.1,1.1-0.5,1.1H54.3L54.7,302.7z"/>
	<path class="pt11" d="M30,302.7l5.7,0.1c0.2,0.2,0.5-0.1,0.9-0.1l7.2,6.9c0.4,0.4,0.1,1.1-0.5,1.1H29.6L30,302.7z"/>
	<polygon class="pt12" points="59.8,218.8 63.9,302.7 53.7,303.5 50.3,263 48.6,239.9 44.8,260.7 38.1,303.2 29.2,303.2 32.4,258.5 
		34.7,222.4 	"/>
	<path class="pt14" d="M40.9,173.8l-3.5-0.7L35,160.6c0,0,1.7-7.3,5.9-6.9c4.2,0.4,9.9-3.2,12.8-1.1c2.4,1.8,2,11.9-4.2,12.6
		L40.9,173.8z"/>
	<path class="pt13" d="M28.9,186c0,0,13-6,30.9-3v32.3c0,0,1.4,4.3,0.7,5.7c0,0-14,7.2-27.8,3.7c0,0-1-2.8,0.7-5.8
		C33.4,218.9,33.5,194.4,28.9,186z"/>
	<path class="pt15" d="M40.8,166.4c0,0-1.9-1.2-2.3,0s-0.5,3.2,1.2,3.1l1.3,3.1l-1.3,10.5c0,0,4.2,3.5,8.3-0.6l0.4-7.5l4.1-1.7
		l-1.9-11.8c0,0-3.7,3.4-7.3,0l-0.7,6.8l-1.4,0.2L40.8,166.4z"/>
	<path class="pt12" d="M106.3,195.8l20.6,2.1l15.8-7.4l3.7,3.7c0,0-12.9,11-16.2,11.9c-3.3,0.9-24.2-1.5-24.2-1.5s-4.3-0.9-5.1-5.4
		C100.1,194.8,106.3,195.8,106.3,195.8z"/>
	
		<ellipse transform="matrix(0.9874 -0.1585 0.1585 0.9874 -29.6821 28.503)" class="pt9" cx="163.8" cy="200.3" rx="37.9" ry="37.9"/>
	
		<ellipse transform="matrix(0.993 -0.1178 0.1178 0.993 -22.3247 20.0892)" class="pt10" cx="158.8" cy="198.9" rx="37.9" ry="37.9"/>
	<path class="pt9" d="M146.7,228.7c-16-6.8-23.7-25.2-17.3-41.4c3.2-8,9.3-14.2,17.1-17.6c7.9-3.4,16.6-3.5,24.6-0.4
		c8,3.2,14.2,9.3,17.6,17.1c3.4,7.9,3.5,16.6,0.4,24.6c-6.5,16.5-25.3,24.6-41.7,18C147.2,228.9,146.9,228.8,146.7,228.7z M171.7,169.9c-0.2-0.1-0.5-0.2-0.8-0.3c-7.9-3.1-16.5-3-24.3,0.4c-7.8,3.4-13.8,9.6-16.9,17.4c-6.4,16,1.3,34.2,17,40.9
		c0.2,0.1,0.5,0.2,0.7,0.3c16.3,6.5,34.8-1.5,41.2-17.8c3.1-7.9,3-16.5-0.4-24.3C185.2,179,179.3,173.1,171.7,169.9z"/>
	<g>
		<path class="pt9" d="M148.4,220.5l2.3-5.5c-3.1-1.4-6-3.8-7.3-5.9l2.8-3.1c1.4,2.1,3.8,4.4,6.8,5.7c3.8,1.6,7.4,0.5,8.7-2.6
			c1.3-3-0.1-5.7-3.4-9.1c-4.6-4.5-7-8.5-4.9-13.2c1.9-4.5,6.6-6.5,11.9-5.2l2.3-5.5l3.4,1.4l-2.2,5.3c3.2,1.5,5.1,3.3,6.2,4.9
			l-2.9,3c-0.9-1.1-2.6-3.3-6-4.8c-4.2-1.8-6.8,0-7.7,2.2c-1.2,2.8,0.2,5.1,4.1,9c4.6,4.7,6.2,8.7,4.2,13.5c-1.8,4.3-6.5,7-12.5,5.6
			l-2.4,5.6L148.4,220.5z"/>
	</g>
	<path class="pt15" d="M84.8,310.9h13c0,0-0.7-2.6-3.5-3.2c-1.1-0.2-2.1-0.8-2.8-1.5c-1-1-1.6-2-1.6-2s7.3-27,10.3-36.2l-6.4-7.2
		l-3.8,3.6c0,0-2.8,25.3-6,39.6c-0.3,1.5-0.7,2.8-1,4C82.6,309.5,83.5,310.9,84.8,310.9z"/>
	<path class="pt15" d="M111.6,274.9l5,30l0.7,4.1c0.2,1.1,1,1.8,2,1.8h10.2c0,0,0.2-2.7-3.1-3.3c-0.7-0.1-1.3-0.5-1.9-0.9h0
		c-2-1.5-2.9-4.4-2.9-5.7c0-1.6-1-29.9-1-29.9L111.6,274.9z"/>
	<path class="pt14" d="M100.9,164c0,0,10.9,0.1,11.1,6.6c0.2,6.5-5.1,14.6-4,17.4c1.1,2.7,0.9,17.9-14,19.3
		c-14.9,1.4-17-8.7-12.6-16.2C85.7,183.5,93.9,165.6,100.9,164z"/>
	<path class="pt13" d="M117.7,217.4l2.2,13.6l3.6,43.9c0,0-15.9,12.2-32.6,5.5c0,0-4.8-3.8-10.9-2.8l14.8-45.8
		c0,0,3.8-13.5,8.1-15.3C110.5,213.4,117.7,217.4,117.7,217.4z"/>
	<path class="pt13" d="M97.1,204l5.8,12.5c0,0,8.7,3.2,14.7,0.8l1.5-12.9c0,0-2.4-3.5-5.1-7.4c-1.8-2.5-7.3-3-9.7-0.9L97.1,204z"/>
	<path class="pt16" d="M134.9,208.6c0,0,8.8-5.8,14.4-2.6l2.9,2.8c0,0-1.2,2-3.2,0.8c-2-1.2-3.8-1-3.8-1l-5.1,2.2L134.9,208.6z"/>
	<path class="pt11" d="M116,302.8l5.7,0.1c0.2,0.2,0.5-0.1,0.9-0.1l7.2,6.9c0.4,0.4,0.1,1.1-0.5,1.1h-14.1L116,302.8z"/>
	<path class="pt11" d="M84,302.8l5.7,0.1c0.2,0.2,0.5-0.1,0.9-0.1l7.2,6.9c0.4,0.4,0.1,1.1-0.5,1.1H82.4L84,302.8z"/>
	<path class="pt15" d="M112,170.6c0,0,1.2,2.5,1.7,9.4c0.5,7-0.4,6.5-0.4,6.5l-3.5,1.1l-1.7,8.1l-8.7,1.2c0,0,4.8-3.1,4.9-9.3
		c0.2-6.2-3.3-3.2-2.6-7.7s2.9,0.2,2.9,0.2S110.5,178.5,112,170.6z"/>
	<path class="pt12" d="M105.7,196.3l15,11.1L138,206l2.2,4.7c0,0-16,5.8-19.3,5.5c-3.4-0.3-22.1-9.9-22.1-9.9s-2.8-2.2-2.9-6.8
		c-0.1-4,2.8-4.8,5.2-4.8C102.7,194.7,104.3,195.3,105.7,196.3z"/>
	<path class="pt13" d="M28.9,186c0,0-10.5,20-6.2,34.7c0,0,8.5,7.7,29.6-5.4l-2-6.6l-18.5,3.1C31.9,211.8,35.9,191.6,28.9,186z"/>
	<path class="pt15" d="M50.3,208.7c0,0,2.9-1.7,6.1-2.3c3.3-0.6,0,1.1,0,1.1s2.9-1,6.3-1.3c3.4-0.3-4.1,6.9-4.1,6.9l-6.7,1.3
		L50.3,208.7z"/>
	<polygon class="pt17" points="227.9,100.1 227.8,100.2 227.8,107.1 170.6,107.1 170.6,116.7 227.8,116.7 227.8,123.5 227.9,123.6 
		240.5,111.9 	"/>
	<polygon class="pt17" points="171.3,150 171.4,149.8 171.4,143 228.6,143 228.6,133.4 171.4,133.4 171.4,126.6 171.3,126.5 
		158.7,138.2 	"/>
	<path class="pt0" d="M295.1,80.3c1.1-1.4,1.7-3.1,1.7-4.9c0-4.4-3.5-7.9-7.9-7.9c-4.4,0-7.9,3.5-7.9,7.9c0,4.4,3.5,7.9,7.9,7.9
		c1.6,0,3.1-0.5,4.4-1.3l3.1,1.6L295.1,80.3z"/>
	<g>
		<path class="pt9" d="M226.2,242.6l-1.8-5.7c-3.3,1-7,1-9.4,0.2l0.2-4.2c2.4,0.7,5.7,0.9,8.9-0.1c4-1.2,6-4.4,5-7.6
			c-1-3.1-3.7-4.3-8.4-4.7c-6.4-0.5-10.8-2-12.3-6.9c-1.4-4.7,0.8-9.2,5.7-11.7l-1.8-5.7l3.5-1.1l1.7,5.5c3.4-0.9,6-0.7,7.9-0.3
			l-0.3,4.1c-1.4-0.3-4.1-0.8-7.7,0.3c-4.3,1.3-5.2,4.4-4.5,6.7c0.9,2.9,3.4,3.7,9,4.3c6.5,0.6,10.4,2.6,11.9,7.6
			c1.4,4.4-0.4,9.6-5.9,12.3l1.8,5.9L226.2,242.6z"/>
	</g>
</g>
</svg>


</b-col>
    </b-row>
    
  </b-container>
</template>

<script>
  

  export default {
    computed: {
      
    },
    components: {
     
    },
    data() {
      return {}
    },
  }
</script>
<style>
.pt0{fill:#EFEFFF;}
	.pt0{fill:#B2AAF2;}
	.pt1{fill:#D2D2FF;}
	.pt2{fill:#EFEFFF;}
	.pt3{fill:#FFFFFF;}
	.pt4{fill:#5148A5;}
	.pt5{fill:#FFB127;}
	.pt6{fill:#EC9F90;}
	.pt7{fill:#001AAE;}
	.pt8{fill:#FCD17D;}
	.pt9{fill:#FFA600;}
	.pt10{fill:#FFD165;}
	.pt11{fill:#3A3A3A;}
	.pt12{fill:#3D38A0;}
	.pt13{fill:#7762EF;}
	.pt14{fill:#2C1E56;}
	.pt15{fill:#ED9C80;}
	.pt16{fill:#FFB0A3;}
	.pt17{fill:#B0AFFD;}
</style>