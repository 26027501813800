<template >
  <b-container fluid class="container" > 
    <loading :active.sync="isWorking" :is-full-page="true"></loading>
    <div v-if="isShowListaTramites">
      <!--Panel mis trámites-->
      <b-row  align-h="center" align="center">
        <b-col cols="12"><h3 style="color:white; margin-top:15px;"> <b-icon icon="archive"/> Mis Trámites</h3></b-col><br><br><br>

        <b-col  cols="12" sm="12" md="9">
          <b-card class="card-tram-list" style="border-radius:20px; margin-left:-5px; margin-right:-5px; min-height:200px;">
            <b-row align="left"><b-col><h4 :style="{color: iconsColor}"><b-icon icon="file-earmark-break-fill"/> En Curso</h4><br></b-col></b-row>
            <b-col align="center" v-for="service in services" :key="service.idUserService">
              <b-row align="left" align-v="center" @click="consultaTramite(service.idUserService)" class="card-tram-btn"  v-if="service.estado!='CONCLUIDO' && service.estado!='CANCELADO'" >
                <b-col cols="12" sm="11">
                  <b-icon variant="info" font-scale="1.5" icon="file-earmark-text-fill"/>
                  <b style="text-transform:capitalize; font-size:90%;"> {{ service.nombre.toLowerCase()}}</b>
                </b-col>
                <b-col class="d-none d-sm-block" cols="1"><b-icon :style="{color:themeColor}" icon="arrow-right"/></b-col>
              </b-row>
            </b-col>
            <b v-if="!hayTramsEnCurso"><b-icon icon="files"/> No tienes trámites en curso</b>
          </b-card>      
          <br>     
        </b-col>
        <b-col  cols="12" sm="12" md="9">
          <b-card class="card-tram-list" style="border-radius:20px; margin-left:-5px; margin-right:-5px; min-height:200px;">
            <b-row align="left"><b-col><h4 :style="{color:iconsColor}"><b-icon icon="file-earmark-break-fill"/> Concluidos</h4><br></b-col></b-row>
            <b-col align="center" v-for="service in services" :key="service.idUserService">
              <b-row align="left" align-v="center" @click="consultaTramite(service.idUserService)" class="card-tram-btn"  v-if="service.estado=='CONCLUIDO'" >
                <b-col cols="12" sm="11">
                  <b-icon variant="success" font-scale="1.5" icon="file-earmark-text-fill"/>
                  <b style="text-transform:capitalize; font-size:90%;"> {{ service.nombre.toLowerCase()}}</b>
                </b-col>
                <b-col class="d-none d-sm-block" cols="1"><b-icon :style="{color:themeColor}" icon="arrow-right"/></b-col>
              </b-row>
            </b-col>
            <b v-if="!hayTramsConcluidos"><b-icon icon="files"/> No tienes trámites concluidos</b>
          </b-card>      
          <br>     
        </b-col>
      </b-row>
    </div>

    <!--TRAMITE INFORMACION-->
    <b-row v-if="!isShowListaTramites" align-h="center">
      <b-col >
        <b-row align="center"><b-col><h4 style="color:white; text-transform:capitalize; margin-top:15px;"><img width="20px;" style="margin-top:-5px;" :src="'../imgs/'+currentService.icon" /> {{currentService.nombre.toLowerCase()}}</h4> </b-col></b-row>
        <br>
        <b-card style="margin-left:-10px; margin-right:-10px; padding-left:5px;" class="c-shadow-sm card-service-info " v-if="currentService != null">          
          <b-row align="left">
            <b-col cols="12">

              <b class="text-md"><b-icon icon="ui-checks-grid"/> Expediente: <font size="2" style="color:green;">GBNT-{{currentService.idUserService}}</font></b><br>
              <b v-if="currentService.estado != 'INICIADO' && currentService.estado != 'POR REVISAR'" class="text-md"><b-icon icon="list-stars"/> Estado: <font size="2" style="color:gray;">{{currentService.estado}}</font></b>
              <div v-if="currentService.estado == 'INICIADO' || currentService.estado == 'POR REVISAR'">
                <b class="text-md" v-if="currentService.statusReqs == 'PENDIENTES' && !isTodosRequisitosEnviados" >Estado: <font style="color:green;" size="2" >Requisitos Incompletos</font></b>
                <b class="text-md" v-if="currentService.statusReqs == 'PENDIENTES' && isTodosRequisitosEnviados" >Estado: <font style="color:green;" size="2" >Solicitud Sin Enviar</font></b>
                <b class="text-md" v-if="currentService.statusReqs == 'POR REVISAR'" >Estado: <font size="2" style="color:#green;">Requisitos En Revisión</font></b>
              </div>  
            </b-col>
          </b-row>
          <br>
          <!--------------------------------------------------Envío de requisitos--------------------------------------------------->
          <b-row :style="innerMarginMobile">
            <b-col cols="12">
              <b-button block v-b-toggle:collapse-1 :style="btnCollapseStepActive">
                <b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="success"/> 1 - Envío de Requisitos
                <span class="when-open"> <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span> 
              </b-button> 
              <b-row>
                <b-col cols="1" class="conector-step-active"></b-col>
                <b-col>
                  <b-collapse :visible="currentService.estado == 'INICIADO' || currentService.estado == 'POR REVISAR'" id="collapse-1" style="width: 100%;"  accordion="my-accordion" role="tabpanel">
                    <b-row>
                      <b-col>
                          <br>
                        <b-alert :show="msjErrorExtensionFile.time" dismissible variant="danger" @dismissed="msjErrorExtensionFile.time=0" @dismiss-count-down="countDownExtension">{{msjErrorExtensionFile.msj}}</b-alert>
                        <b-alert :show="msjErrorSizeFile.time" dismissible variant="danger" @dismissed="msjErrorSizeFile.time=0" @dismiss-count-down="countDownSize">{{msjErrorSizeFile.msj}}</b-alert>
                        
                        <div v-for="requisito in currentService.requisitos" :key="requisito.idRequisito">
                          
                          <div style="margin-left:5px; margin-right: 15px; ">
                            
                            <!--nombre del requisito-->
                            <b style="text-transform:capitalize; font-size:90%;">{{requisito.nombre.toLowerCase()}} </b>
                            <!--info-->
                            <b v-if="requisito.estado != 'ACEPTADO' && requisito.estado != 'POR REVISAR'" style="margin-left: 2px; margin-right: -10px;" :title="requisito.descripcion" >
                              <b-icon @click="showInfoRequisito(requisito.descripcion)" icon="question-circle-fill" font-scale=".7" variant="secondary"></b-icon>
                            </b> 

                            <b-link v-if="requisito.linkFormato!=null&&requisito.linkFormato!=''" :href="requisito.linkFormato" target="_blank" size="sm">  Descargar Formato</b-link>
                            
                            <!--form-->
                            <b-row v-if="requisito.estado != 'ACEPTADO' && requisito.estado != 'POR REVISAR' && requisito.tipo == 'FORMULARIO'">
                              <b-col cols="11" style="margin-right:-20px;">
                                <b-form-input size="sm" :placeholder="'Ingrese ' + requisito.nombre" v-model="requisito.model"></b-form-input>
                              </b-col>
                              <b-col align="left" cols="1">
                                <b-button class="btn-back-purple" size="sm" :style="marginInnerElement" :disabled="requisito.model== null || requisito.model==''" @click="guardarRequisito(requisito.idRequisito, requisito.model, 'form')"><b-icon rotate="45" font-scale="1" icon="cursor-fill"/></b-button>
                              </b-col>
                            </b-row>
                            
                            <!--file-->
                            <div v-if="requisito.tipo == 'ARCHIVO'">
                              <b-form-file  size="sm" :id="requisito.idRequisito.toString()" @change="updateRequisito" v-if="requisito.estado != 'ACEPTADO' && requisito.estado != 'POR REVISAR'" v-model="requisito.model" browse-text="Buscar" placeholder="Selecciona archivo . . ." ></b-form-file>
                            </div>

                            <!--maps-->
                            <b-row v-if="requisito.estado != 'ACEPTADO' && requisito.estado != 'POR REVISAR' && requisito.tipo == 'MAPA'">
                              <b-col cols="12">
                                <b-button size="sm"   @click="verModalMapaRequisito(requisito)">Indicar en el mapa <b-icon icon="geo-alt-fill"/></b-button>
                              </b-col>
                            </b-row>

                            <span class="text-req-opcional" v-if="requisito.opcional && requisito.estado=='PENDIENTE'">Opcional</span>
                            <div style=" text-transform:capitalize; font-size:90%;" >
                              <div v-if="requisito.estado=='POR REVISAR' || requisito.estado=='ACEPTADO'"> 
                                <b-link v-if="requisito.tipo == 'ARCHIVO'" size="sm" @click="descargarRequisito(requisito.idRequisito, requisito.nombre, false)">Enviado <b-icon font-scale="1" icon="eye-fill"/></b-link>
                                <b-link v-if="requisito.tipo == 'FORMULARIO'" size="sm" @click="descargarRequisito(requisito.idRequisito, requisito.nombre, true)">Enviado <b-icon font-scale="1" icon="eye-fill"/></b-link>
                                <b-link v-if="requisito.tipo == 'MAPA'" size="sm" @click="descargarRequisito(requisito.idRequisito, requisito.nombre, true)">Enviado <b-icon font-scale="1" icon="eye-fill"/></b-link>
                              </div>                              
                            </div>

                          </div><br>
                        </div>
                        <!----------------SOLICITUD------------->
                        <b-row v-if="currentService.solicitud!=null" ><b-col><b-button style="margin-left:-5px;" @click="descargaSolicitud" size="sm" variant="link"  ><b-icon icon="file-text"/> Descargar Solicitud</b-button><br><br></b-col></b-row>
                        <!--------------- FIRMA SOLICITUD  ------------------->
                        <b-row style="margin-left:-10px;" v-if="currentService.solicitud==null && isTodosRequisitosEnviados" align="left">
                          <b-col cols="12"><b-button pill size="md" v-b-modal.modal-firma class="btn-back-purple">Enviar Solicitud <b-icon icon="arrow-right-circle"></b-icon></b-button><br></b-col>
                        </b-row>
                      </b-col>
                      <b-col style="margin-top:-40px;" cols="7" class="d-none d-lg-block"><ReqsVector ></ReqsVector></b-col>
                    </b-row>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!----------------------------------------------------------Pago------------------------------------------------------------>
          <b-row :style="innerMarginMobile" v-if="currentService.estado == 'INICIADO' || currentService.estado == 'POR REVISAR'">
            <b-col cols="12">
              <b-button disabled :style="btnCollapseStepInactive"><b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="secondary"/> 2 - Realizar Pago
                <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
              </b-button>
            </b-col>
            <b-col cols="1" class="conector-step-inactive"></b-col>
          </b-row>
          <b-row :style="innerMarginMobile" v-if="currentService.estado != 'INICIADO' && currentService.estado != 'POR REVISAR'">
            <b-col cols="12">
              <b-button block v-b-toggle="'collapse-2'" :style="btnCollapseStepActive">
                <b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="success"/> 2 - Realizar Pago
                <span class="when-open"> <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
              </b-button>
              <b-row>
                <b-col v-if="currentService.estado != 'INICIADO' && currentService.estado != 'POR REVISAR'" cols="1" class="conector-step-active"></b-col>
                <b-col>
                  <b-collapse :visible="currentService.estado == 'PENDIENTE PAGO'" id="collapse-2" style="width: 100%;" accordion="my-accordion" role="tabpanel">
                    <b-row >
                      <b-col>
                        <div  v-if="!currentService.gratuito">
                          <br>
                          <b-row  align="left"  v-if="currentService.estado=='PENDIENTE PAGO'">
                            <b-col >
                              <b-row><b-button variant="link" style="margin-left:8px; margin-bottom:7px;"  @click="descargarOrdenPago"><h6>Descargar Formato de Pago <b-icon icon="arrow-down-square"/></h6></b-button></b-row>
                            </b-col>
                          </b-row>

                          <b-row align="left"  v-if="currentService.estado=='PENDIENTE PAGO'">
                            <b-col >
                              <b-row><b-button size="md" pill  style="margin-left:20px;" class="btn-back-purple" @click="pagarEnLinea()">Realizar Pago En Línea <b-icon icon="arrow-right-circle"></b-icon></b-button></b-row><br>
                            </b-col>
                          </b-row>
                          <!---
                          <b-row align-h="center" >
                            <b-col cols="12" align="center">
                              <b-button size="sm" pill variant="success" @click="getService(currentService.idUserService)" > <b-icon icon="arrow-repeat"></b-icon> Actualizar Información</b-button><br> <br>
                            </b-col>
                          </b-row>-->

                          <b-row class="row-pago" v-if="currentService.statusPago=='PAGADO'">
                            <b-col cols="12" >
                              <b style="text-transform:capitalize;  font-size:90%;">Información del pago; </b>
                            </b-col>
                            <b-col lg="4" cols="12">
                              <b-card-text>
                                <b-icon variant="primary" icon="sticky-fill"/> Estado: <font :color="colorPago">{{currentService.statusPago}}</font>
                              </b-card-text>
                            </b-col>
                            <b-col lg="4" cols="12">
                              <b-card-text>
                                <b-icon variant="primary" icon="calendar-fill"/> Fecha: <font :color="colorPago">{{currentService.fecPago}}</font>
                              </b-card-text>
                            </b-col>
                            <b-col lg="4" cols="12">
                              <b-card-text>
                                <b-icon variant="primary" icon="aspect-ratio-fill"/> No. Recibo: <font :color="colorPago">{{currentService.noRecibo}}</font>
                              </b-card-text>
                            </b-col>
                          </b-row>
                        </div>
                        <b-card class="c-shadow" v-if="currentService.gratuito">
                          <b-row class="row-pago" >
                            <b-col><h6>Este trámite es gratuito.</h6></b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col  style="margin-top:-40px;" cols="7" class="d-none d-lg-block"><PagoVector/> </b-col>
                    </b-row>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-------------------------------------------------------Entregables----------------------------------------------------->
          <b-row :style="innerMarginMobile" v-if="currentService.estado == 'INICIADO' || currentService.estado == 'POR REVISAR' || currentService.estado == 'PENDIENTE PAGO'"><b-col>
            <b-button disabled :style="btnCollapseStepInactive"><b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="secondary"/> 3 - Entrega de Documentos
              <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
            </b-button>
          </b-col></b-row>
          <b-row :style="innerMarginMobile" v-if="currentService.estado != 'INICIADO' && currentService.estado != 'POR REVISAR' && currentService.estado != 'PENDIENTE PAGO'"  >
            <b-col cols="12">
              <b-button block v-b-toggle="'collapse-3'" :style="btnCollapseStepActive">
                <b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="success"/> 3 - Entrega de Documentos
                <span class="when-open"> <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
              </b-button>
              <b-collapse :visible="currentService.estado == 'PAGADO' || currentService.estado == 'POR ENTREGAR'" id="collapse-3" style="width: 100%;" accordion="my-accordion" role="tabpanel">
                <div style="margin-left:45px; margin-right: 15px; ">
                  <b-row >
                    <b-col cols="12" v-if="currentService.statusEnts == 'PENDIENTE' || currentService.statusEnts == 'GENERADO'">
                      <b style=" font-size:90%;">Tu documentación esta siendo preparada, nosotros te notificaremos cuando este lista</b><br><br><br><br>
                    </b-col>

                    <b-col v-if="currentService.statusEnts == 'LIBERADO' || currentService.statusEnts == 'RECIBIDO'">
                      <b-row v-for="entregable in currentService.entregables" :key="entregable.idEntregable">
                        <b-col cols="12"><br><b>{{entregable.nombre}}</b><br></b-col>
                        <b-col cols="12"><span style="color:green;" v-if="entregable.fecRecibido!=null">Fecha de recibido: </span>{{entregable.fecRecibido}}</b-col>

                        <b-col cols="12"><br><b-button pill class="btn-back-purple" v-if="entregable.idEntregable != null" @click="descargarEntregable(entregable.idEntregable, entregable.nombre)"><b-icon icon="file-text"/> Descargar</b-button><br></b-col>

                        <div v-if="entregable.fecRecibido==null && entregable.nombre=='Formato de Cita'">
                          <b-card v-if="currentService.instruccionesEntrega!=null && currentService.instruccionesEntrega!=''">
                            <b>Instrucciones:</b><br>
                            <span v-for="instruccion in instruccionesEntregables" :key="instruccion"> {{instruccion}}<br></span>
                          </b-card>
                          <b-card v-if="currentService.obsEntregables!=null && currentService.obsEntregables!=''" style="margin-top:5px;">
                            <b>Fecha y Hora de Entrega:</b><br>
                            <font v-for="observacion in observacionesEntregables" :key="observacion" class="text-danger">{{observacion}}<br></font>
                          </b-card>
                        </div>

                      </b-row>
                    </b-col>
                    <b-col style="margin-top:-40px;" cols="7" class="d-none d-lg-block"><EntregaVector/> </b-col>
                  </b-row>    
                </div>
                <b-row><b-col  cols="12" class="d-block d-lg-none"><br><EntregaVector/> </b-col></b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <!------ CANCELAR TRÁMITE------>
          <b-row align-h="end">
            <b-col cols="6" sm="5" md="4" lg="3" >
              <br><br>
                <b-button style="color:red;" pill block v-if="currentService.estado != 'CANCELADO' && currentService.statusEnts == 'PENDIENTE'"   size="sm" variant="link" @click="isShowModalConfirmCancel=true">Cancelar Trámite <b-icon font-scale=".9" shift-v="2" icon="trash"/></b-button>
              <br><br>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--modals-->
    <b-modal title="Descripcion del requisito" v-model="isShowModalDescRequisito" size="md" hide-footer>
      {{descripcionRequisito}}
    </b-modal>

    <b-modal title="Ubica el marcador en la dirección correcta" v-model="isShowModalMaps" size="lg" hide-footer>
      <div>
        <b-row>
          <b-col>
            <GmapMap ref="mapreq" :center="centerMap" :zoom="17" map-type-id="hybrid" style="width: 100%; height: 700px" @center_changed="refreshMarker">
              <GmapMarker key="marker" :position="centerMarker"/>
            </GmapMap>
          </b-col>
        </b-row>
        <b-row align="center">
          <b-col cols="12" class="mt-3">
            <b-button @click="guardarUbicacionRequisitoActual" pill variant="success">Guardar ubicación <b-icon icon="geo-alt-fill"/></b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-model="isShowMsjLengthRequisito" size="md" hide-footer>
      <div class="error">El contenido es demasiado largo</div>
    </b-modal>

    <!--- ya no se usa
    <b-modal v-model="isShowModalPdfLinea" size="lg" hide-footer>
        <b-embed id="embed1" type="iframe" :src='pdfGenerated'></b-embed>
    </b-modal>-->

    <b-modal title="Requisito" v-model="isShowRequisitoTypeForm" size="md" hide-footer>
      <b-form-input disabled v-model="requisitoForm"></b-form-input>
    </b-modal>

    <b-modal hide-header v-model="isShowModalConfirmCancel" size="md" hide-footer>
      <div>¿Desea CANCELAR el trámite?.<br>Esta acción no podrá revertise</div>
      <b-row align-h="between">
        <b-col cols="12">
            <br>
            <b-form-textarea v-model.trim="motivoCancelacion"  placeholder="Ingresa el motivo de cancelación"  rows="3" max-rows="5"></b-form-textarea><br>
          </b-col>
        <b-col sm="6" cols="5">
          <b-button pill :disabled="motivoCancelacion==null || motivoCancelacion.length<10" block variant="success" @click="cancelarTramite" size="sm">Si</b-button>
        </b-col>
        <b-col sm="6" cols="5">
          <b-button pill block variant="danger" @click="isShowModalConfirmCancel=false" size="sm">No</b-button>
        </b-col>
      </b-row>
    </b-modal>

  <b-modal id="modal-firma" v-model="isShowModalFirmaElectronica" size="lg" hide-footer>
    <template #modal-title><h3>Ingresa tu firma electrónica</h3></template>
    <firma-electronica ></firma-electronica>
    <b-row align-h="center" align="center"><b-col cols="11" sm="9" md="8" lg="6" xl="5" style="margin-top:10px; ">
        <b-button pill v-b-modal.modal-firmasimple size="lg" block variant="secondary">No tengo Firma Electrónica</b-button><br>
    </b-col></b-row>
  </b-modal>

  <b-modal id="modal-firmasimple" v-model="isShowModalFirmaElectronicaSimple" size="lg" hide-footer >
      <template #modal-title><h3>Continuar sin firma electrónica</h3></template>
      <firma-electronica-simple></firma-electronica-simple>
    </b-modal>

  <b-modal v-model="isShowModalObservaciones" size="lg" hide-footer scrollable modal-class="file-modal">
      <template #modal-title><h4>Tienes una nueva notificación</h4></template>
      <vue-pdf-app v-if="currentService!=null && currentService.obsPdf!=null" :config="configPdf" :pdf="'data:application/pdf;base64,'+currentService.obsPdf" ></vue-pdf-app>
      <b-row >
        <b-col cols="12" v-if="currentService!=null && currentService.obsTxt!=null">
          <span>Mensaje</span>
          <b-form-textarea rows="5" max-rows="12" disabled class="text-area" v-model.trim="currentService.obsTxt"></b-form-textarea>
          <br>
        </b-col>
      </b-row>
      
  </b-modal>

  </b-container>
</template>



<script>
  import { mapState} from 'vuex';
  import { currentService, descargarRequisitoUser, registrarRequisitoUser, descargarOrdenPagoUser, generarUrlPagoUser, descargarEntregableUser, cancelarTramiteUser } from '@/axios-tools';
  import {getClassStatusTramite, downloadFileToB64} from '@/vue-tools';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import FirmaElectronica from './FirmaElectronica.vue';
  import FirmaElectronicaSimple from './FirmaElectronicaSimple.vue';
  import ReqsVector from '../logos/ReqsVector.vue';
  import PagoVector from '../logos/PagoVector.vue';
  import EntregaVector from '../logos/EntregaVector.vue';
  import Vue from 'vue';
  import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
  import * as VueGoogleMaps from 'vue2-google-maps' 
  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyD4T_Xis3hk4odIjra8zHQIMksnot0N5i8',
  }})
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  import VuePdfApp from "vue-pdf-app";
  // import this to use default icons for buttons
  import "vue-pdf-app/dist/icons/main.css";


  export default {
    computed: {
      ...mapState({
        perfil: state => state.user.perfil,
        services: state => state.user.perfil.services,
        currentService: state => state.user.currentService,
        token: state => state.user.token,
        idServiceStart: state => state.user.idServiceStart,
        filesB64: state => state.user.filesB64,
        isWorking: state => state.user.isWorking,
        msjErrorUser: state => state.user.msjErrorUser,
        msjErrorRequisitoUser: state => state.user.msjErrorRequisitoUser,
        pdfGenerated: state => state.user.pdfGenerated,
        requisitoForm: state => state.user.requisitoForm,
        iconsColor: state => state.user.iconsColor,
        principalColor : state => state.user.principalColor
      }),
      hayTramsEnCurso: function(){
        if(this.services==null){return false}
        for(var i=0; i<this.services.length; i++){
          if(this.services[i].estado!='CONCLUIDO' && this.services[i].estado!='CANCELADO'){return true;}
        }
        return false;
      },
      hayTramsConcluidos: function(){
        if(this.services==null){return false}
        for(var i=0; i<this.services.length; i++){
          if(this.services[i].estado=='CONCLUIDO'){return true;}
        }
        return false;
      },
      isShowListaTramites: {
        get () { return this.$store.state.user.isShowListaTramites },
        set (value) { this.$store.commit("updateIsShowListaTramites", value) }
      },
      isShowModalFirmaElectronica: {
        get () { return this.$store.state.user.isShowModalFirmaElectronica},
        set (value) { this.$store.commit("updateIsShowModalFirmaElectronica", value) }
      },
      isShowModalFirmaElectronicaSimple: {
        get () { return this.$store.state.user.isShowModalFirmaElectronicaSimple},
        set (value) { this.$store.commit("updateIsShowModalFirmaElectronicaSimple", value) }
      },
      isShowModalObservaciones: {
        get () { return this.$store.state.user.isShowModalObservaciones},
        set (value) { this.$store.commit("updateIsShowModalObservaciones", value) }
      },
      btnCollapseStepActive: function(){
        return 'margin-left:-7px; text-align:left; background-color:rgb(1,1,1,0); border:white;'+'color:'+this.principalColor+';' + 'font-size:17px; font-weight: bolder; box-shadow:none;';
      },
      btnCollapseStepInactive: function(){
        return 'margin-left:-7px; text-align:left; background-color:rgb(1,1,1,0); border:white;'+'color:gray; font-size:17px; font-weight: bolder';
      },
      marginRow: function(){
        var border = parseInt(this.width/5);
        return this.isMobile ? "margin-right:-25px; margin-left:-25px; " : "margin-right:"+border+"px; margin-left:"+border+"px;  "
      },
      innerMarginMobile: function(){
        return this.isMobile ? "margin-right:-35px; margin-left:-35px; " : ""
      },
      marginInnerElement:function() {
        return "margin-left: -10px; margin-right: -10px;"
      },
      colorPago: function(){
        if(this.currentService.statusPago == "PAGADO"){
          return "green";
        }else if(this.currentService.statusPago == "PENDIENTE" || this.currentService.statusPago == "CANCELADO"){
          return "red";
        }
        return "#000000";
      },
      instruccionesRequisitos: function(){
        return this.currentService.instruccionesReq.split("¬");
      },
      instruccionesEntregables: function(){
        return this.currentService.instruccionesEntrega.split("¬");
      },
      observacionesEntregables: function(){
        return this.currentService.obsEntregables.split("¬");
      },
      isShowRequisitoTypeForm:{
        get: function() {
          return this.$store.state.user.isShowRequisitoTypeForm;
        },
        set: function(newIsShowRequisitoTypeForm) {
          this.$store.commit("updateIsShowRequisitoTypeForm", newIsShowRequisitoTypeForm);
        }
      },
      isTodosRequisitosEnviados: function(){
        for(var i=0; i<this.currentService.requisitos.length; i++){
          var estadoReq= this.currentService.requisitos[i].estado
          if(estadoReq == 'PENDIENTE' || estadoReq == 'NO ACEPTADO'){
            return false;
          }
        }
        return true;
      },
      isShowModalPdfLinea: {
        get: function() {
          return this.$store.state.user.isShowModalPdfLinea;
        },
        set: function(newIsShowModalPdfLinea) {
          this.$store.commit("updateIsShowModalPdfLinea", newIsShowModalPdfLinea);
        }
      },
      isMobile() {
        return this.width <= 800
      }
    },
    components: {
      Loading,
      FirmaElectronica,
      FirmaElectronicaSimple,
      ReqsVector,
      PagoVector,
      EntregaVector,
      VuePdfApp
    },
    data() {
      return {
        width: window.innerWidth,
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        descripcionRequisito:'',
        isShowModalDescRequisito:false,
        isShowMsjLengthRequisito:false,
        isShowModalConfirmCancel:false,
        isShowModalMaps:false,
        centerMap:{lat: 19.4679606,lng: -99.1558783},
        centerMarker:{lat: 19.4679606,lng: -99.1558783},
        requisitoEnMapa:null,
        msjErrorExtensionFile:{
          msj:"",
          time:0
        },
        msjErrorSizeFile:{
          time:0,
          msj:""
        },
        motivoCancelacion:'',
        configPdf:{
          toolbar:{
            toolbarViewerRight:{
              openFile:false
            }
          }
        },
        requisitoMapaActual:null,
      }
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.width = window.innerWidth
      })
    },
    methods: {
      getClassStatusTramite :getClassStatusTramite,
      countDownErrorUser(dismissCountDown){
          this.$store.commit("updateTimeMsjErrorUser", dismissCountDown);
      },
      countDownSize(dismissCountDown){
        this.msjErrorSizeFile.time = dismissCountDown;
      },
      countDownExtension(dismissCountDown){
        this.msjErrorExtensionFile.time = dismissCountDown;
      },
      showInfoRequisito(descRequisito){
        this.descripcionRequisito=descRequisito;
        this.isShowModalDescRequisito=true;
      },
      consultaTramite(idUserService){
        this.getService(idUserService);
      },
      cancelarTramite(){
        cancelarTramiteUser(this.cliente, this.token, this.currentService.idUserService, this.$store, this.$router, this.motivoCancelacion);
        this.isShowModalConfirmCancel=false;
        this.motivoCancelacion='';
      },
      guardarRequisito(idRequisitoUser, contenido, tipo){
        //if(contenido.length <= 10000){
          registrarRequisitoUser(this.cliente, this.token, this.currentService.idUserService, idRequisitoUser, btoa(contenido), tipo, this.$store, this.$router);
        //}else{
          //alert(contenido);
          //this.isShowMsjLengthRequisito=true;
        //}
      },
      updateRequisito(evt){
        //this.$store.commit("updateIsWorking", true);
        var f = evt.target.files[0];
        var reader = new FileReader();
        //Creamos una refrencia local al objeto this para poder acceder desde las funciones anidadas a los objetos cliente, token .. etc
        var thisObject = this;
        reader.onload = (function() {
          return function(theFile) {
            var binaryData = theFile.target.result;
            // Convertir datos binarios a base 64
            var base64String = window.btoa(binaryData);
            // mostrando el archivo convertido a base64
            var tipo = f.name.split('.').pop();
            var idRequisitoUser=evt.target.id;
            if(tipo == 'pdf' || tipo == 'xlsx' || tipo == 'txt' || tipo == 'png' || tipo == 'jpg' || tipo == 'jpeg'){
              if(f.size <= 3000000){
                registrarRequisitoUser(thisObject.cliente, thisObject.token, thisObject.currentService.idUserService, idRequisitoUser, base64String, tipo, thisObject.$store, thisObject.$router);
              }else{
                thisObject.msjErrorSizeFile.msj="El requisito no debe exceder 3mb de tamaño";
                thisObject.msjErrorSizeFile.time=4;
              }
            }else{
              thisObject.msjErrorExtensionFile.msj="La extensión del archivo no es válida";
              thisObject.msjErrorExtensionFile.time=4;
            }
          };
        })(f);
        reader.onerror = function(error){
          alert("error al cargar archivo"+error);
        };
        // Leer en el archivo de imagen como una URL de datos.
        reader.readAsBinaryString(f);
      },
      showPortalContribuyente(){
        this.$router.push("/"+this.$route.params.municipio+"/portalcontribuyente");
        this.$store.commit("updateIsShowCardsUser", false);
      },
      descargarEntregable(idEntregable, nombreEntregable){ /* Método envia la petición para obtener el entregable que se quiere descargar, en él se llama una función que guarda el archivo */
        descargarEntregableUser(this.$route.params.municipio, this.currentService.idUserService, this.token, idEntregable, this.$store, nombreEntregable, this.$router);
      },
      descargarOrdenPago(){ /* Método que envia la petición para obtener la orden de pago que se quiere descargar*/
        descargarOrdenPagoUser(this.currentService.idUserService, this.token, this.$store, this.$router);
      },
      pagarEnLinea(){ /* Método que envia la petición para obtener la orden de pago que se quiere descargar, en él se llama una función que guarda el archivo */
        generarUrlPagoUser(this.currentService.idUserService, this.token, this.$store, this.$router);
      },
      descargarRequisito(idRequisito, nombreRequisito, isTipoForm){ /* Método envia la petición GET del requisito a descargar, en él se llama una función que guarda el archivo */
        descargarRequisitoUser(this.$route.params.municipio, this.currentService.idUserService, this.token, idRequisito, this.$store, nombreRequisito, isTipoForm, this.$router);
      },
      showPanelCatalogo(){
        this.$router.push('/' + this.$route.params.municipio + '/tramitesuser');
      },
      getService(idUserService){
        currentService(this.$route.params.municipio, this.token, idUserService, this.perfil.curp, this.$store, this.$router);
      },
      descargaSolicitud(){
        if(this.currentService.solicitud!=null){
          downloadFileToB64(this.currentService.solicitud, 'pdf', 'solicitud')
        }
      },
      refreshMarker(latLng){
        this.centerMarker= {
          lat: latLng.lat(),
          lng: latLng.lng(),
        }
      },
      guardarUbicacionRequisitoActual(){
        this.guardarRequisito(this.requisitoMapaActual.idRequisito, this.centerMarker, 'mapa')
        this.isShowModalMaps=false;
      },
      verModalMapaRequisito(requisito){
        this.isShowModalMaps=true;
        this.requisitoMapaActual = requisito;
      },
      goToCatalogo(){
        this.$router.push('/'+this.$route.params.municipio+'/catalogotramites')
      }
    }
  }
</script>
<style scoped>

.text-req-opcional{
  color:#D932AA; 
  font-size: 80%;
}
.green{
  color:#0C8004;
}
div.error{
  color:red;
}
.msj-status-reqs{
  color:#D97E00;
}
.c-shadow{
  background-color:#FFFFFF;
  box-shadow: 20px 20px 50px 7px #B5B5B5;
  margin-bottom:10px;
}
.text-danger{
  color:#FF0000;
}
.button-collapse{
  margin-top:5px;
}
.row-pago{
  margin-left:0px;
  margin-bottom:15px;
}
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 50%;
  font-size:20px;
  width: 80px;
  height: 80px;
  position:absolute;
  left:30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  
}
.card-tram-btn{
  box-shadow: 2px 1px 5px -2px #535353;
  margin-bottom:12px;
  border-radius: 10px;
  cursor:pointer;
  padding:7px;
  min-height: 50px;
}
.button-mistram-list{
  margin:-15px; 
  background-color:white; 
  border:none; 
  text-align:left;
  font-weight: bold;
  color: #535353;
  text-transform: capitalize;
  box-shadow: none;
}
.card-tram-list{
  box-shadow: 5px 5px 20px -2px #535353;
  margin-bottom:10px;
  border-radius: 20px;
}
.file-modal .modal-dialog {
    height:100% !important;
}

</style>