<template>
  <b-container fluid>
    <b-row align="center" align-h="center">
		<b-col cols="7" >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 396 437.3" style="enable-background:new 0 0 396 437.3;" xml:space="preserve">

<g id="BACKGROUND">
</g>
<g id="OBJECTS">
	<g>
		<g>
			<path class="rt0" d="M379,197.6c0,97.1-78.7,175.9-175.9,175.9S27.2,294.7,27.2,197.6c0-37.2,11.5-71.7,31.2-100.1
				c31.8-45.8,84.7-75.8,144.7-75.8C300.3,21.7,379,100.4,379,197.6z"/>
			<g>
				<g>
					<path class="rt0" d="M357.2,189.5c0,23.6-4.2,47.2-12.7,69.2c-8.5,22-21.1,42.4-37.2,59.7c-16.1,17.3-35.5,31.5-57.1,41.2
						c-21.6,9.7-45.2,14.8-68.9,14.9c-23.7,0.1-47.4-4.8-69.1-14.4c-21.7-9.6-41.5-23.6-57.7-41c-16.2-17.3-29.1-37.9-37.7-60
						c-8.6-22.1-13-45.9-13.1-69.7c0.1-23.8,4.4-47.5,13.1-69.7c8.6-22.1,21.4-42.7,37.7-60c16.2-17.4,36-31.4,57.7-41
						c21.7-9.6,45.5-14.5,69.1-14.4c23.7,0.1,47.3,5.2,68.9,14.9c21.6,9.7,41,23.9,57.1,41.2c16.1,17.3,28.7,37.7,37.2,59.7
						C353,142.3,357.2,165.9,357.2,189.5z M357.2,189.5c-0.1-23.6-4.4-47.1-13-69.1c-8.5-22-21.2-42.3-37.3-59.5
						c-16.1-17.2-35.6-31.1-57.1-40.6c-21.5-9.5-45-14.3-68.5-14.2c-23.5,0.1-46.9,5.1-68.2,14.8c-21.4,9.6-40.6,23.7-56.5,40.8
						c-15.9,17.1-28.5,37.3-36.9,59.2c-8.4,21.8-12.6,45.2-12.6,68.5c0,23.4,4.2,46.7,12.6,68.6c8.4,21.8,20.9,42,36.9,59.2
						c15.9,17.1,35.1,31.2,56.5,40.8c21.3,9.6,44.7,14.7,68.2,14.8c23.5,0.1,46.9-4.7,68.5-14.2c21.5-9.5,41.1-23.4,57.1-40.6
						c16.1-17.2,28.8-37.5,37.3-59.5C352.8,236.6,357.1,213.1,357.2,189.5z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="rt0" d="M387.3,196.6c0,22.9-3.8,45.8-12,67.3c-8.1,21.5-20.6,41.3-36.7,57.8c-16,16.5-35.8,29.4-57.3,37.9
						c-21.5,8.5-44.7,12.2-67.7,11.7c-23-0.6-46-5.6-67-15.1c-10.5-4.7-20.5-10.5-29.8-17.3c-9.3-6.7-17.9-14.4-25.6-22.9
						c-15.4-17-27.3-37.2-34.7-59c-7.5-21.7-10.7-44.8-10-67.8c0.7-23,5.3-45.9,13.9-67.3c4.3-10.7,9.5-21,15.7-30.8
						c6.2-9.7,13.3-18.9,21.1-27.3c15.8-16.8,34.9-30.6,56.1-39.9c21.2-9.3,44.4-13.8,67.6-13.2c23.1,0.6,46.1,6.1,66.8,16.5
						c20.7,10.3,39.1,24.9,54,42.5c14.9,17.5,26.5,37.8,34.1,59.5C383.6,150.8,387.4,173.7,387.3,196.6z M387,196.6
						c-0.1-22.8-4-45.7-12-67.1c-8-21.4-19.8-41.3-34.9-58.4c-15.1-17-33.3-31.2-53.8-41c-5.1-2.5-10.3-4.6-15.7-6.6
						c-5.3-1.9-10.7-3.6-16.2-4.9c-11-2.7-22.3-4.2-33.6-4.4c-11.3-0.3-22.7,0.7-33.8,2.9c-11.1,2.2-21.9,5.6-32.3,10.1
						c-20.8,9-39.6,22.4-55.2,38.9C84.1,82.6,71.6,102,63,123c-8.6,21-13.5,43.6-14.5,66.3c-1,22.7,1.9,45.7,9.2,67.4
						c7.3,21.6,18.9,41.8,34.4,58.6c7.7,8.4,16.3,16,25.6,22.6c9.3,6.6,19.3,12.2,29.7,16.7c20.9,9,43.6,13.7,66.3,14
						c11.4,0.2,22.7-0.7,33.9-2.6c11.2-1.9,22.1-5,32.7-9.1c21.2-8.1,40.6-20.7,56.8-36.6c16.2-16,29-35.4,37.4-56.6
						c4.2-10.6,7.3-21.6,9.4-32.9C386,219.5,387,208.1,387,196.6z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="rt1" d="M96.2,56.9c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8c0.3,0.7-0.1,1.5-0.8,1.8
						C97.3,58,96.5,57.6,96.2,56.9z"/>
				</g>
				<g>
					<path class="rt1" d="M324.5,129.9c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8c0.3,0.7-0.1,1.5-0.8,1.8
						C325.6,130.9,324.8,130.6,324.5,129.9z"/>
				</g>
				<g>
					<path class="rt1" d="M68.4,129.9c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8c0.3,0.7-0.1,1.5-0.8,1.8
						C69.5,130.9,68.7,130.6,68.4,129.9z"/>
				</g>
				<g>
					<path class="rt1" d="M286.9,55.4c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8c0.3,0.7-0.1,1.5-0.8,1.8
						C287.9,56.4,287.1,56.1,286.9,55.4z"/>
				</g>
				<g>
					<path class="rt1" d="M75.8,241.3c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8c0.3,0.7-0.1,1.5-0.8,1.8
						C76.9,242.3,76.1,242,75.8,241.3z"/>
				</g>
				<g>
					<path class="rt1" d="M350.4,218.8c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8c0.3,0.7-0.1,1.5-0.8,1.8
						C351.5,219.8,350.7,219.5,350.4,218.8z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="rt1" d="M171.8,48.7c-0.3,0-0.5-0.2-0.5-0.5v-5.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v5.5
							C172.3,48.5,172,48.7,171.8,48.7z"/>
					</g>
					<g>
						<path class="rt1" d="M174.5,46H169c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h5.5c0.3,0,0.5,0.2,0.5,0.5
							C175,45.8,174.8,46,174.5,46z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="rt1" d="M357.2,175.9c-0.3,0-0.5-0.2-0.5-0.5V170c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v5.5
							C357.7,175.7,357.5,175.9,357.2,175.9z"/>
					</g>
					<g>
						<path class="rt1" d="M359.9,173.2h-5.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5.5c0.3,0,0.5,0.2,0.5,0.5
							S360.2,173.2,359.9,173.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="rt1" d="M328.6,274.8c-0.3,0-0.5-0.2-0.5-0.5v-5.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v5.5
							C329.1,274.5,328.9,274.8,328.6,274.8z"/>
					</g>
					<g>
						<path class="rt1" d="M331.3,272h-5.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h5.5c0.3,0,0.5,0.2,0.5,0.5
							C331.8,271.8,331.6,272,331.3,272z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="rt1" d="M34.7,185.2c-0.3,0-0.5-0.2-0.5-0.5v-5.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v5.5
							C35.2,184.9,34.9,185.2,34.7,185.2z"/>
					</g>
					<g>
						<path class="rt1" d="M37.4,182.4h-5.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h5.5c0.3,0,0.5,0.2,0.5,0.5
							C37.9,182.2,37.7,182.4,37.4,182.4z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path id="XMLID_390_" class="rt2" d="M304.9,377.8c0,2.5-49.5,4.6-110.5,4.6s-110.5-2.1-110.5-4.6c0-2.5,49.5-4.6,110.5-4.6
				S304.9,375.3,304.9,377.8z"/>
			<g>
				<path class="rt3" d="M303,371.1c0.1,0.1,0.3-6.4,10-12.2c9.9-6.2,30.6-4.1,33.5-10.5c3.1-6.8-10-5.9-15.3-11.4
					c-5.4-5.6-7.2-8.1,0.7-9.3c7.8-1,12.6-1.4,12.9-7.8c0.3-6.5,12.9-2.7,19.6-7.1c6.9-4.4,3.6-11-8.9-11.3c-12.3,0-12.7-2.1-9-3.8
					c3.6-1.7,14.5-4.4,10.1-7.9c-4.5-3.6-11.5-1-7.6-5c3.8-4,12.6-6.6,8.6-11.4c-3.9-4.9-14.7-5-21.5,6.7
					c-6.6,11.7-8.8,14.9-14.8,15.9c-5.8,1.1-11-5.5-18.2-3.3c-7.2,2.3-6.2,9.1-2.2,13.8c4.1,4.7,10.8,14,6.2,17.8
					c-4.4,3.8-9.2-2.4-15.3-3.8c-6.8-1.6-14.8,3.9-8.9,13.6c6.2,9.6,16,17.4,15.1,38.7C299.6,372.1,301.3,371.6,303,371.1z"/>
				<path class="rt4" d="M288.4,326.3c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,12.5,12.2,16.9,26.7c0.5-1.3,0.9-2.7,1.4-4c0,0,0,0,0,0
					c4.5-12.5,9.2-23.2,13.8-32.3c-0.9-1.3-8.4-11.2-18.5-20.2c-0.2-0.1-0.2-0.4,0-0.5c0.1-0.2,0.4-0.2,0.5,0
					c9.6,8.5,16.9,17.9,18.4,20c0.7-1.4,1.4-2.7,2.1-4c0-0.1,0.1-0.2,0.2-0.3c3.2-6.1,6.4-11.3,9.4-15.8c0-0.1,0.1-0.2,0.2-0.3
					c0,0,0,0,0,0c10.4-15.8,18.3-22.3,18.4-22.4c0.2-0.1,0.4-0.1,0.5,0.1c0.1,0.2,0.1,0.4-0.1,0.5c-0.2,0.1-7.6,6.3-17.5,21.1
					c9.5-4.8,19.5-4.1,19.6-4.1c0.2,0,0.4,0.2,0.3,0.4c0,0.2-0.2,0.4-0.4,0.3c-0.1,0-10.8-0.7-20.4,4.7c-2.9,4.4-5.9,9.4-9.1,15.3
					c17.5-4.9,35.9-3.4,36.1-3.4c0.2,0,0.4,0.2,0.3,0.4c0,0.2-0.2,0.4-0.4,0.3c-0.2,0-19-1.5-36.5,3.6c-5.3,9.9-10.8,21.9-16,36.3
					c12.3-4.1,31.3-3.1,31.5-3.1c0.2,0,0.4,0.2,0.3,0.4c0,0.2-0.2,0.4-0.4,0.3c-0.2,0-19.6-1-31.8,3.2c-2.5,7-4.9,14.5-7.2,22.7
					c-0.1,0.2-0.2,0.3-0.4,0.3c0,0,0,0,0,0c-0.2-0.1-0.3-0.3-0.3-0.5c1.8-6.3,3.6-12.2,5.5-17.7c-4-14.7-16.8-27.2-17-27.3
					C288.2,326.6,288.2,326.4,288.4,326.3z"/>
			</g>
			<g id="XMLID_23_">
				<g>
					<path class="rt3" d="M102.7,284.9c1.6-51.9-23.4-103.3-65.2-134.2l0.8-1.1c42.2,31.1,67.4,83,65.8,135.3L102.7,284.9z"/>
				</g>
				<g id="XMLID_35_">
					<g>
						<path class="rt3" d="M46.4,157c-3.3-6.5-8.9-11.7-15.6-14.5c-1.6-0.7-3.6-1.2-5.1-0.3c-0.4,0.2-0.8,0.6-1,1
							c-0.3,0.7,0.1,1.5,0.6,2.2c2.4,3.4,6.4,5.3,10.3,6.7C39.5,153.5,42.3,155.1,46.4,157"/>
					</g>
					<g>
						<path class="rt3" d="M57,167.3c-5.6-4.7-12.8-7.2-20.1-7.1c-1.8,0-3.8,0.3-4.9,1.7c-0.3,0.4-0.5,0.9-0.5,1.3
							c0.1,0.8,0.8,1.4,1.4,1.8c3.6,2.2,8,2.4,12.1,2.1C49.3,166.8,52.5,167.2,57,167.3"/>
					</g>
					<g>
						<path class="rt3" d="M69.7,181.7c-4.9-5.3-11.9-8.8-19.1-9.5c-1.8-0.2-3.8-0.1-5,1.1c-0.3,0.3-0.6,0.8-0.6,1.3
							c0,0.8,0.6,1.4,1.2,1.9c3.3,2.6,7.6,3.3,11.8,3.6C62.1,180.3,65.2,181.1,69.7,181.7"/>
					</g>
					<g>
						<path class="rt3" d="M80,197.3c-4.9-5.3-11.9-8.8-19.1-9.5c-1.8-0.2-3.8-0.1-5,1.1c-0.3,0.3-0.6,0.8-0.6,1.3
							c0,0.8,0.6,1.4,1.2,1.9c3.3,2.6,7.6,3.3,11.8,3.6C72.4,195.9,75.5,196.7,80,197.3"/>
					</g>
					<g>
						<path class="rt3" d="M91,219.6c-4.4-5.8-10.9-9.9-18-11.4c-1.7-0.4-3.7-0.5-5.1,0.6c-0.4,0.3-0.7,0.7-0.8,1.2
							c-0.1,0.8,0.4,1.5,1,2c3,2.9,7.2,4.1,11.4,4.8C83.6,217.5,86.6,218.5,91,219.6"/>
					</g>
					<g>
						<path class="rt3" d="M97.4,236.6c-3.8-5.6-9.9-10-16.8-12.3c-1.7-0.6-3.7-1-5.1-0.2c-0.4,0.2-0.8,0.5-0.9,0.9
							c-0.2,0.7,0.3,1.3,0.8,1.9c2.7,2.9,6.8,4.5,10.8,5.7C90.2,233.7,93.1,235,97.4,236.6"/>
					</g>
					<g>
						<path class="rt3" d="M61.6,172.2c2.9-6.9,3.2-15,0.9-22.3c-0.6-1.8-1.4-3.7-3.1-4.5c-0.5-0.2-1-0.3-1.4-0.1
							c-0.7,0.3-1.1,1.1-1.3,1.9c-1,4.2,0.1,8.7,1.6,12.9C59.7,164.3,60.3,167.6,61.6,172.2"/>
					</g>
					<g>
						<path class="rt3" d="M71,183.7c4.4-6,6.7-13.8,6.2-21.5c-0.1-1.9-0.5-4-1.9-5.1c-0.4-0.3-0.9-0.5-1.4-0.5
							c-0.8,0.1-1.3,0.8-1.7,1.6c-2,3.8-2,8.5-1.6,12.9C71.1,175.5,70.9,178.9,71,183.7"/>
					</g>
					<g>
						<path class="rt3" d="M81.4,200c5.5-5,9.3-12.2,10.4-19.8c0.3-1.9,0.3-4-0.9-5.4c-0.3-0.4-0.8-0.7-1.2-0.7
							c-0.8-0.1-1.5,0.5-2,1.2c-2.7,3.3-3.7,7.9-4.1,12.3C83.1,192,82.2,195.2,81.4,200"/>
					</g>
					<g>
						<path class="rt3" d="M92.5,221.9c4.3-6.1,6.4-13.9,5.7-21.6c-0.2-1.9-0.6-4-2.1-5c-0.4-0.3-0.9-0.5-1.4-0.4
							c-0.8,0.1-1.3,0.9-1.7,1.6c-1.9,3.9-1.8,8.5-1.3,12.9C92.4,213.8,92.3,217.1,92.5,221.9"/>
					</g>
					<g>
						<path class="rt3" d="M97.9,238.9c6.6-3.5,12-9.6,14.8-16.7c0.7-1.7,1.3-3.8,0.4-5.4c-0.2-0.4-0.6-0.9-1-1
							c-0.7-0.3-1.6,0.2-2.2,0.7c-3.5,2.6-5.5,6.8-7,11C101.5,231.5,99.9,234.5,97.9,238.9"/>
					</g>
				</g>
			</g>
			<g id="XMLID_37_">
				<g>
					<path class="rt3" d="M289.5,297.5c-11.2-50.7,3.7-105.9,39-144l-1-0.9c-35.6,38.4-50.7,94.1-39.4,145.2L289.5,297.5z"/>
				</g>
				<g id="XMLID_38_">
					<g>
						<path class="rt3" d="M320.9,161.4c2-7,6.6-13.2,12.7-17.2c1.5-1,3.3-1.8,5-1.3c0.5,0.1,0.9,0.4,1.2,0.8
							c0.4,0.7,0.1,1.5-0.2,2.3c-1.7,3.8-5.3,6.4-8.9,8.6C327.1,156.7,324.7,158.8,320.9,161.4"/>
					</g>
					<g>
						<path class="rt3" d="M312.4,173.4c4.6-5.6,11.3-9.5,18.4-10.7c1.8-0.3,3.8-0.4,5.1,0.8c0.4,0.3,0.7,0.7,0.7,1.2
							c0.1,0.8-0.5,1.5-1.1,2c-3.1,2.8-7.4,3.8-11.5,4.3C319.9,171.6,316.9,172.5,312.4,173.4"/>
					</g>
					<g>
						<path class="rt3" d="M302.7,190c3.9-6.1,10-10.8,17-12.9c1.7-0.5,3.7-0.8,5.2,0.2c0.4,0.3,0.8,0.7,0.9,1.1
							c0.2,0.8-0.3,1.5-0.8,2.1c-2.7,3.2-6.9,4.7-10.9,5.7C309.9,187.2,307,188.5,302.7,190"/>
					</g>
					<g>
						<path class="rt3" d="M295.4,207.2c3.9-6.1,10-10.8,17-12.9c1.7-0.5,3.7-0.8,5.2,0.2c0.4,0.3,0.8,0.7,0.9,1.1
							c0.2,0.8-0.3,1.5-0.8,2.1c-2.7,3.2-6.9,4.7-10.9,5.7C302.6,204.5,299.7,205.8,295.4,207.2"/>
					</g>
					<g>
						<path class="rt3" d="M288.8,231.2c3.2-6.5,8.8-11.8,15.5-14.6c1.6-0.7,3.6-1.2,5.1-0.4c0.4,0.2,0.8,0.6,1,1
							c0.3,0.7-0.1,1.5-0.6,2.2c-2.4,3.4-6.4,5.4-10.3,6.8C295.7,227.7,293,229.3,288.8,231.2"/>
					</g>
					<g>
						<path class="rt3" d="M285.7,249c2.7-6.2,7.8-11.7,14.2-15.2c1.6-0.9,3.4-1.6,5-1.1c0.4,0.1,0.8,0.4,1,0.7c0.3,0.6,0,1.4-0.4,2
							c-2.1,3.4-5.8,5.7-9.6,7.6C292.2,244.9,289.6,246.7,285.7,249"/>
					</g>
					<g>
						<path class="rt3" d="M308.9,179.2c-4.1-6.2-5.9-14.1-5-21.8c0.2-1.9,0.7-3.9,2.2-5c0.4-0.3,0.9-0.5,1.4-0.4
							c0.8,0.1,1.3,0.9,1.6,1.6c1.8,3.9,1.6,8.6,0.9,13C309.2,171,309.3,174.4,308.9,179.2"/>
					</g>
					<g>
						<path class="rt3" d="M301.7,192.2c-5.5-5.1-9.1-12.3-10.1-19.9c-0.2-1.9-0.2-4,1-5.4c0.3-0.4,0.8-0.7,1.3-0.7
							c0.8-0.1,1.5,0.6,2,1.2c2.7,3.4,3.6,8,3.9,12.4C300.2,184.2,301,187.4,301.7,192.2"/>
					</g>
					<g>
						<path class="rt3" d="M294.6,210.1c-6.4-3.9-11.4-10.2-13.9-17.5c-0.6-1.8-1-3.9-0.1-5.4c0.3-0.4,0.6-0.8,1.1-1
							c0.8-0.2,1.5,0.3,2.2,0.8c3.3,2.8,5.1,7.1,6.3,11.3C291.5,202.6,292.9,205.6,294.6,210.1"/>
					</g>
					<g>
						<path class="rt3" d="M287.8,233.8c-5.4-5.2-8.8-12.5-9.6-20.1c-0.2-1.9-0.2-4,1.1-5.3c0.3-0.4,0.8-0.7,1.3-0.7
							c0.8,0,1.4,0.6,1.9,1.3c2.6,3.4,3.4,8.1,3.7,12.5C286.4,225.7,287.1,229,287.8,233.8"/>
					</g>
					<g>
						<path class="rt3" d="M285.6,251.4c-7.1-2.2-13.5-7.2-17.7-13.6c-1-1.6-1.9-3.5-1.4-5.3c0.1-0.5,0.4-0.9,0.8-1.2
							c0.7-0.4,1.6-0.1,2.3,0.2c3.9,1.9,6.6,5.7,8.9,9.5C280.7,244.9,282.9,247.4,285.6,251.4"/>
					</g>
				</g>
			</g>
			<g>
				<path class="rt1" d="M265.9,334.7c6.3-5.9,15.3-8.9,23.9-8.1c5.8,0.6,12.2,3.7,13.5,9.4c0.6,2.7,0.4,6.4,3,7.4
					c2.5,1,5-1.6,7.6-2.4c4.3-1.4,9.3,3.5,7.8,7.8c-0.7,2-2.3,3.7-2.2,5.7c0.2,2.8,3.7,4.3,6.5,3.8c2.8-0.4,5.3-2.1,8-2.8
					c2.7-0.7,6.3,0,7.2,2.7c0.9,2.4-0.6,5.6,1.2,7.3c0.8,0.7,2,0.9,3,1c23.7,2.2,49.8,6.5,47.1,10.7c-0.7,1.2-2.6,0.8-3.9,0.8
					c-50.4-0.8-95.7-0.8-146.6-0.8"/>
			</g>
			<g>
				<path class="rt3" d="M95.9,376.7c-0.1,0.1-0.3-6.4-10-12.2c-9.9-6.2-30.6-4.1-33.5-10.5c-3.1-6.8,10-5.9,15.3-11.4
					c5.4-5.6,7.2-8.1-0.7-9.3c-7.8-1-12.6-1.4-12.9-7.9c-0.3-6.5-12.9-2.7-19.6-7.1c-6.9-4.4-3.6-11,8.9-11.3c12.3,0,12.7-2.1,9-3.8
					c-3.6-1.7-14.5-4.4-10.1-7.9c4.5-3.6,11.5-1,7.6-5c-3.8-4-12.6-6.6-8.6-11.4c3.9-4.9,14.7-5,21.5,6.7
					c6.6,11.7,8.8,14.9,14.8,15.9c5.8,1.1,11-5.5,18.2-3.3c7.2,2.3,6.2,9.1,2.2,13.8c-4.1,4.7-10.8,14-6.2,17.8
					c4.4,3.8,9.2-2.4,15.3-3.8c6.8-1.6,14.8,3.9,8.9,13.6c-6.2,9.6-15.3,12.7-14.4,34C100.1,373.1,97.6,377.2,95.9,376.7z"/>
				<path class="rt4" d="M110.6,331.9c-0.1-0.1-0.4-0.1-0.5,0c-0.1,0.1-12.5,12.2-16.9,26.7c-0.5-1.3-0.9-2.7-1.4-4c0,0,0,0,0,0
					c-4.5-12.5-9.2-23.2-13.8-32.3c0.9-1.3,8.4-11.2,18.5-20.2c0.2-0.1,0.2-0.4,0-0.5c-0.1-0.2-0.4-0.2-0.5,0
					c-9.6,8.5-16.9,17.9-18.4,20c-0.7-1.4-1.4-2.7-2.1-4c0-0.1-0.1-0.2-0.2-0.3c-3.2-6.1-6.4-11.3-9.4-15.8c0-0.1-0.1-0.2-0.2-0.3
					c0,0,0,0,0,0c-10.4-15.8-18.3-22.3-18.4-22.4c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5c0.2,0.1,7.6,6.3,17.5,21.1
					c-9.5-4.8-19.5-4.1-19.6-4.1c-0.2,0-0.4,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.3c0.1,0,10.8-0.7,20.4,4.7c2.9,4.4,5.9,9.4,9.1,15.3
					c-17.5-4.9-35.9-3.4-36.1-3.4c-0.2,0-0.4,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.3c0.2,0,19-1.5,36.5,3.6c5.3,9.9,10.8,21.9,16,36.3
					C78.6,350,59.5,351,59.4,351c-0.2,0-0.4,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.3c0.2,0,19.6-1,31.8,3.2c2.5,7,4.5,13,6.8,21.1
					c0.1,0.2,0.3,0.7,0.5,0.6c0,0,0-0.1,0.1-0.2c0.2-0.1,0.5,0.2,0.4,0c-1.8-6.3-3.5-11.2-5.3-16.8c4-14.7,16.8-27.2,17-27.3
					C110.7,332.3,110.7,332,110.6,331.9z"/>
			</g>
			<g>
				<path class="rt1" d="M135.8,334.7c-6.3-5.9-15.3-8.9-23.9-8.1c-5.8,0.6-12.2,3.7-13.5,9.4c-0.6,2.7-0.4,6.4-3,7.4
					c-2.5,1-5-1.6-7.6-2.4c-4.3-1.4-9.3,3.5-7.8,7.8c0.7,2,2.3,3.7,2.2,5.7c-0.2,2.8-3.7,4.3-6.5,3.8c-2.8-0.4-5.3-2.1-8-2.8
					c-2.7-0.7-6.3,0-7.2,2.7c-0.9,2.4,0.6,5.6-1.2,7.3c-0.8,0.7-2,0.9-3,1c-23.7,2.2-49.8,6.5-47.1,10.7c0.7,1.2,2.6,0.8,3.9,0.8
					c50.4-0.8,95.7-0.8,146.6-0.8"/>
			</g>
		</g>
		<g>
			<path class="rt5" d="M291.9,377.8H96.8c-3.8,0-6.9-3.1-6.9-6.9V89.2c0-3.8,3.1-6.9,6.9-6.9h195.1c3.8,0,6.9,3.1,6.9,6.9v281.6
				C298.9,374.7,295.8,377.8,291.9,377.8z"/>
			<path id="XMLID_377_" class="rt6" d="M282.8,366.2H106c-2.2,0-4-1.8-4-4V97.9c0-2.2,1.8-4,4-4h176.7c2.2,0,4,1.8,4,4v264.3
				C286.8,364.4,285,366.2,282.8,366.2z"/>
			<path class="rt7" d="M274.7,357.9H114.1c-2.7,0-5-2.2-5-5V107.2c0-2.7,2.2-5,5-5h160.6c2.7,0,5,2.2,5,5v245.7
				C279.7,355.7,277.4,357.9,274.7,357.9z"/>
			<g>
				<g>
					<path id="XMLID_384_" class="rt8" d="M196.5,56.3c-6.1,0-11.1,5-11.1,11.1v14.2h22.2c0,0,0-11.3,0-14.2
						C207.6,61.2,202.6,56.3,196.5,56.3z M196.5,73.7c-3.4,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2
						S199.9,73.7,196.5,73.7z"/>
					<path id="XMLID_378_" class="rt9" d="M202.7,67.5c0-3.4-2.8-6.2-6.2-6.2c-0.3,0-0.6,0-0.9,0.1c0.9-0.4,1-0.6,2.5-0.6
						c3.4,0,6.2,2.8,6.2,6.2c0,3.1-1.6,4.8-4,5.4C201.7,71.2,202.7,69.4,202.7,67.5z M188.8,80.1V65.9c0-3.9,1.7-7.3,4.7-9.2
						c-4.6,1.3-8,5.6-8,10.7v14.2h22.2c0,0,0-0.6,0-1.4H188.8z"/>
				</g>
				<path id="XMLID_376_" class="rt8" d="M261.6,87.2v11.1H127.2V87.2c0-4.3,3.5-7.8,7.8-7.8h118.8
					C258.1,79.4,261.6,82.9,261.6,87.2z"/>
				<path id="XMLID_375_" class="rt9" d="M261.6,95.5v2.8H127.2V87.2c0-4.3,3.5-7.8,7.8-7.8h1.1c-2.1,2-3.7,5-3.7,8.1v8H261.6z"/>
			</g>
			<g>
				<g class="rt10">
					<g id="XMLID_367_">
						<g id="XMLID_374_">
							<path class="rt6" d="M184.1,131.1h-27.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h27.7c1,0,1.7,0.8,1.7,1.7
								C185.9,130.3,185.1,131.1,184.1,131.1z"/>
						</g>
						<g id="XMLID_373_">
							<path class="rt6" d="M267.4,139.9h-21.1c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h21.1c1,0,1.7,0.8,1.7,1.7
								C269.1,139.2,268.3,139.9,267.4,139.9z"/>
						</g>
						<g id="XMLID_372_">
							<path class="rt6" d="M237.2,139.9h-80.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h80.7c1,0,1.7,0.8,1.7,1.7
								C238.9,139.2,238.1,139.9,237.2,139.9z"/>
						</g>
						<g id="XMLID_371_">
							<path class="rt6" d="M267.4,148.8H156.4c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h110.9c1,0,1.7,0.8,1.7,1.7
								S268.3,148.8,267.4,148.8z"/>
						</g>
						<g id="XMLID_370_">
							<path class="rt6" d="M267.4,157.6h-35.3c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h35.3c1,0,1.7,0.8,1.7,1.7
								C269.1,156.8,268.3,157.6,267.4,157.6z"/>
						</g>
						<g id="XMLID_369_">
							<path class="rt6" d="M223.3,157.6h-66.9c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h66.9c1,0,1.7,0.8,1.7,1.7
								C225,156.8,224.3,157.6,223.3,157.6z"/>
						</g>
						<g id="XMLID_368_">
							<path class="rt6" d="M267.4,131.1h-74.1c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h74.1c1,0,1.7,0.8,1.7,1.7
								C269.1,130.3,268.3,131.1,267.4,131.1z"/>
						</g>
					</g>
					<g id="XMLID_358_">
						<g id="XMLID_365_">
							<path class="rt6" d="M184.1,194.6h-27.7c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h27.7c1,0,1.7,0.8,1.7,1.7
								S185.1,194.6,184.1,194.6z"/>
						</g>
						<g id="XMLID_364_">
							<path class="rt6" d="M267.4,203.4h-21.1c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h21.1c1,0,1.7,0.8,1.7,1.7
								C269.1,202.7,268.3,203.4,267.4,203.4z"/>
						</g>
						<g id="XMLID_363_">
							<path class="rt6" d="M237.2,203.4h-80.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h80.7c1,0,1.7,0.8,1.7,1.7
								C238.9,202.7,238.1,203.4,237.2,203.4z"/>
						</g>
						<g id="XMLID_362_">
							<path class="rt6" d="M267.4,212.3H156.4c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h110.9c1,0,1.7,0.8,1.7,1.7
								C269.1,211.5,268.3,212.3,267.4,212.3z"/>
						</g>
						<g id="XMLID_361_">
							<path class="rt6" d="M267.4,221.1h-35.3c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h35.3c1,0,1.7,0.8,1.7,1.7
								S268.3,221.1,267.4,221.1z"/>
						</g>
						<g id="XMLID_360_">
							<path class="rt6" d="M223.3,221.1h-66.9c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h66.9c1,0,1.7,0.8,1.7,1.7
								S224.3,221.1,223.3,221.1z"/>
						</g>
						<g id="XMLID_359_">
							<path class="rt6" d="M267.4,194.6h-74.1c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h74.1c1,0,1.7,0.8,1.7,1.7
								S268.3,194.6,267.4,194.6z"/>
						</g>
					</g>
					<g id="XMLID_349_">
						<g id="XMLID_356_">
							<path class="rt6" d="M184.1,255.9h-27.7c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h27.7c1,0,1.7,0.8,1.7,1.7
								S185.1,255.9,184.1,255.9z"/>
						</g>
						<g id="XMLID_355_">
							<path class="rt6" d="M267.4,264.7h-21.1c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h21.1c1,0,1.7,0.8,1.7,1.7
								S268.3,264.7,267.4,264.7z"/>
						</g>
						<g id="XMLID_354_">
							<path class="rt6" d="M237.2,264.7h-80.7c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h80.7c1,0,1.7,0.8,1.7,1.7
								S238.1,264.7,237.2,264.7z"/>
						</g>
						<g id="XMLID_353_">
							<path class="rt6" d="M267.4,273.5H156.4c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h110.9c1,0,1.7,0.8,1.7,1.7
								C269.1,272.8,268.3,273.5,267.4,273.5z"/>
						</g>
						<g id="XMLID_352_">
							<path class="rt6" d="M267.4,282.4h-35.3c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h35.3c1,0,1.7,0.8,1.7,1.7
								C269.1,281.6,268.3,282.4,267.4,282.4z"/>
						</g>
						<g id="XMLID_351_">
							<path class="rt6" d="M223.3,282.4h-66.9c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h66.9c1,0,1.7,0.8,1.7,1.7
								C225,281.6,224.3,282.4,223.3,282.4z"/>
						</g>
						<g id="XMLID_350_">
							<path class="rt6" d="M267.4,255.9h-74.1c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h74.1c1,0,1.7,0.8,1.7,1.7
								S268.3,255.9,267.4,255.9z"/>
						</g>
					</g>
					<g id="XMLID_308_">
						<g id="XMLID_347_">
							<path class="rt6" d="M184.1,318.3h-27.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h27.7c1,0,1.7,0.8,1.7,1.7
								C185.9,317.5,185.1,318.3,184.1,318.3z"/>
						</g>
						<g id="XMLID_346_">
							<path class="rt6" d="M267.4,327.1h-21.1c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h21.1c1,0,1.7,0.8,1.7,1.7
								C269.1,326.3,268.3,327.1,267.4,327.1z"/>
						</g>
						<g id="XMLID_345_">
							<path class="rt6" d="M237.2,327.1h-80.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h80.7c1,0,1.7,0.8,1.7,1.7
								C238.9,326.3,238.1,327.1,237.2,327.1z"/>
						</g>
						<g id="XMLID_344_">
							<path class="rt6" d="M267.4,335.9H156.4c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h110.9c1,0,1.7,0.8,1.7,1.7
								C269.1,335.2,268.3,335.9,267.4,335.9z"/>
						</g>
						<g id="XMLID_343_">
							<path class="rt6" d="M267.4,344.8h-35.3c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h35.3c1,0,1.7,0.8,1.7,1.7
								S268.3,344.8,267.4,344.8z"/>
						</g>
						<g id="XMLID_342_">
							<path class="rt6" d="M223.3,344.8h-66.9c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h66.9c1,0,1.7,0.8,1.7,1.7
								S224.3,344.8,223.3,344.8z"/>
						</g>
						<g id="XMLID_341_">
							<path class="rt6" d="M267.4,318.3h-74.1c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h74.1c1,0,1.7,0.8,1.7,1.7
								C269.1,317.5,268.3,318.3,267.4,318.3z"/>
						</g>
					</g>
				</g>
				<g id="XMLID_128_">
					<path id="XMLID_306_" class="rt11" d="M145,152.1h-21.2c-1.2,0-2.1-0.9-2.1-2.1v-21.2c0-1.2,0.9-2.1,2.1-2.1H145
						c1.2,0,2.1,0.9,2.1,2.1V150C147.1,151.2,146.1,152.1,145,152.1z"/>
					<path id="XMLID_305_" class="rt11" d="M145,215.4h-21.2c-1.2,0-2.1-0.9-2.1-2.1v-21.2c0-1.2,0.9-2.1,2.1-2.1H145
						c1.2,0,2.1,0.9,2.1,2.1v21.2C147.1,214.5,146.1,215.4,145,215.4z"/>
					<path id="XMLID_304_" class="rt11" d="M145,278.7h-21.2c-1.2,0-2.1-0.9-2.1-2.1v-21.2c0-1.2,0.9-2.1,2.1-2.1H145
						c1.2,0,2.1,0.9,2.1,2.1v21.2C147.1,277.7,146.1,278.7,145,278.7z"/>
					<path id="XMLID_303_" class="rt11" d="M145,342h-21.2c-1.2,0-2.1-0.9-2.1-2.1v-21.2c0-1.2,0.9-2.1,2.1-2.1H145
						c1.2,0,2.1,0.9,2.1,2.1v21.2C147.1,341,146.1,342,145,342z"/>
					<g id="XMLID_297_">
						<path class="rt8" d="M134.4,148.4c-0.6,0-1.1-0.3-1.5-0.7l-9.6-11.6c-0.7-0.8-0.6-2,0.3-2.7c0.8-0.7,2-0.6,2.7,0.3l7.9,9.5
							l17-26.8c0.6-0.9,1.8-1.2,2.7-0.6c0.9,0.6,1.2,1.8,0.6,2.7L136,147.5c-0.3,0.5-0.9,0.9-1.5,0.9
							C134.4,148.4,134.4,148.4,134.4,148.4z"/>
					</g>
					<g id="XMLID_132_">
						<path class="rt8" d="M134.4,211.2c-0.6,0-1.1-0.3-1.5-0.7l-9.6-11.6c-0.7-0.8-0.6-2,0.3-2.7c0.8-0.7,2-0.6,2.7,0.3l7.9,9.5
							l17-26.8c0.6-0.9,1.8-1.2,2.7-0.6c0.9,0.6,1.2,1.8,0.6,2.7L136,210.3c-0.3,0.5-0.9,0.9-1.5,0.9
							C134.4,211.2,134.4,211.2,134.4,211.2z"/>
					</g>
					<g id="XMLID_36_">
						<path class="rt8" d="M134.4,274.2c-0.6,0-1.1-0.3-1.5-0.7l-9.6-11.6c-0.7-0.8-0.6-2,0.3-2.7c0.8-0.7,2-0.6,2.7,0.3l7.9,9.5
							l17-26.8c0.6-0.9,1.8-1.2,2.7-0.6c0.9,0.6,1.2,1.8,0.6,2.7L136,273.3c-0.3,0.5-0.9,0.9-1.5,0.9
							C134.4,274.2,134.4,274.2,134.4,274.2z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g>
		<path class="rt12" d="M185.6,292.2c-3,3.2-5.1,23.7-5.2,24.5c-0.1,0.8-15.1,9.2-15.1,9.2l2.1,2.4c0,0,16-4,18.9-7.5
			c2.4-2.9,8.1-27.1,8.1-27.1S190.6,286.9,185.6,292.2z"/>
		<ellipse class="rt2" cx="208.9" cy="430.4" rx="44.7" ry="3.1"/>
		<g>
			<path class="rt13" d="M231.7,417.3c0,0,1.2,2.5,1.8,3.2c0.6,0.8,3.2-1,3.2-1.6c0-0.6-2.3-4.5-2.3-4.5"/>
			<path class="rt14" d="M237.9,419.2c0.6,0.9,1.5,2.5,1.5,2.5l-2.3,2.2l-0.6-0.3c0,0-0.4,1.1-1.1,1.8c-0.5,0.5-1.7,1.4-2.4,1.7
				c-2.3,1.1-4.2,1.3-4.5,1c-1.1-1.2,2-2.8,2.5-3.2c1.6-1.2,2.1-3.1,1.9-4.2c-0.1-0.8-0.4-0.9,0.1-1c0,0,0.4-0.1,0.8,0.1
				c0.7,0.4,1.6,0.3,2.1-0.2c0.3-0.3,0.6-0.7,0.7-1.2C236.6,418.5,237.3,418.3,237.9,419.2z"/>
		</g>
		<g>
			<path class="rt13" d="M187.5,422.2c0,0-0.6,1.5-0.5,2.4c0.1,1,3.2,1,3.6,0.5c0.4-0.5,0.6-4.9,0.6-4.9"/>
			<path class="rt14" d="M191.4,426c0,1-0.3,2.6-0.3,2.6h-3.7l-0.2-0.6c0,0-1.2,0.6-2.1,0.6h-3.1c-2.3,0-3.5-0.3-3.6-0.7
				c-0.2-1.6,2.5-1.5,3.2-1.5c2,0,4.2-0.9,4.7-1.9c0.3-0.7,0.2-0.9,0.6-0.8c0,0,0.4,0.1,0.6,0.5c0.4,0.7,1.1,1.1,1.9,1
				c0.5-0.1,0.9-0.2,1.2-0.6C190.7,424.7,191.4,425,191.4,426z"/>
		</g>
		<path class="rt15" d="M206.1,355.8c0,0.1-3-3.3-3-3.1c-1.2,5.9-6.7,25.8-8.5,31v0c-0.3,0.7-1.7,2.3-2.8,2.6
			c-2.7,0.7-7-0.2-7.3-5.3c0-0.6,0.1-1.2,0-1.8c-0.8-7.9,6.8-42.7,7-44.9c0.2-2.2,4.5-0.6,5.8-1
			C203.4,331.2,207.7,347.5,206.1,355.8z"/>
		<path class="rt15" d="M195,386.5c-1.2-3.7,0.6-8,0.6-8s-10.5-8.1-11.1-3.2c-0.1,0.4-0.4,3.7-0.4,5.6c0,6.8,2.4,28.1,2.2,41.2v0.9
			c0,0,1.8,0.3,4.8,0.3c0.1,0,0.3,0,0.4,0c0.9-8.1,2.8-19.2,3.7-30.3C195.5,390.3,195.2,387.1,195,386.5z"/>
		<path class="rt15" d="M221.3,387c-2.1-2.7-4.3-3.9-4.9-6c-1.5-4.8-1.1-24.4-1.2-29.8c0.4-1.1,4.4-12.2-3.7-20.4
			c0,0-7.3,3.3-13.1,3.7c-4.6,0.3-6.9-0.2-6.9-0.2s4.3,6.9,4.6,10c0.4,5.2,9.6,36.7,10.3,38.4c1.6,3.9,3.4,5.5,3.9,6.3
			c4.1,5.6,13.6,17.4,20.9,28.7l0.5,0.7c0,0,1.5-0.5,4.2-2.5C232,408.5,227.9,395.7,221.3,387z"/>
		<path class="rt12" d="M185.6,292.2c1.5-1.7,7.8-4.3,14.1-4.1c4.3,0.1,18,1.5,19.4,4.4c0.7,1.5,0.6,15.5-0.4,20.7
			c-0.7,3.9-3,14-3,16.3c0,2.3,1.3,3.3,0.7,4c-1.3,1.5-11.2,2.2-15.4,2.2c-3.8,0-10.2-1-10.5-2.2c-0.5-1.8,0.7-1.7,0.9-3.6
			c0.2-1.9,1.1-14.2-0.1-16c-1.7-2.9-4.4-10.9-4.5-13.1C186.7,298.8,184.2,293.9,185.6,292.2z"/>
		<path class="rt13" d="M196,282.8c0,0,0,5.2-0.3,5.4c-0.2,0.2-1.2,0.7-1.2,0.7s0.7,4.5,1.9,4.2c1.1-0.3,8.6-4.1,8.6-4.1
			s-1.1-0.4-1.1-0.9c0-0.4,1-8.6,1-8.6"/>
		<g>
			<path class="rt16" d="M198.1,265.6c-0.9-0.7-2.2-0.7-3.2-0.2c-1,0.5-1.8,1.4-2.2,2.5c-0.4,1-0.5,2.2,0,3.2
				c-0.8,0.2-1.2,1.1-1.2,1.9c0,0.9,0.5,1.7,1,2.4c0.4,0.7,1.4,1.1,2.2,0.9c0.6-0.1,1-0.5,1.4-1c0.3-0.5,0.6-1,0.8-1.5
				c1-2.5,1.6-5.1,2.1-7.7"/>
		</g>
		<path class="rt13" d="M201.3,263.8c-1.1,0.3-4-0.3-4.8,0.9c-0.8,1.2-1.5,4.1-1.8,4.9c-0.6,1.6-1,3.3-1.4,4.9
			c-0.6,2.1-2,6.1,0.1,7.9c0.6,0.5,1.4,0.6,2.1,0.7c1,0.2,2,0.4,2.9,0.6c1.5,0.3,3.2,0.6,4.7,0.1c2-0.7,3.1-3.4,3.7-5.3l3-10.8
			L201.3,263.8z"/>
		<g>
			<path class="rt13" d="M205.7,279.5c0,0-0.1,0.5,0.3,0.8c0.3,0.3,0.8,0.2,1.2,0c1-0.5,1.6-1.6,1.7-2.7c0.1-1.3-0.7-2.5-1.8-2
				c-0.7,0.3-1,1.1-0.9,1.8"/>
		</g>
		<g>
			<path class="rt17" d="M208.5,273.6c-0.4,1.1-1.3,3.5-2.3,3.3c-1-0.2,0.4-4.1,1.2-5.6c-1.2,0-2.7-0.2-3.2-1.3
				c-0.1-0.3-0.2-0.6-0.4-0.7c-0.2,0-0.4,0.1-0.6,0.3c-0.9,0.6-2,0-2.7-0.8c-0.2-0.2-0.3-0.4-0.6-0.4c-0.1,0-0.3,0.1-0.4,0.2
				c-1.4,1-5.2,1-5.2-0.8c-1.2,0.6-2-0.9-2-2c0.1-1.1,0.9-2.8,2.6-2.8c-0.1-1.3,0.9-2.1,2-2.9c1-0.8,2.3-1.1,3.6-1.2
				c2-0.1,4.4,0.7,5.1,2.7c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0,0.5,0c1.6-0.4,3.3-0.2,4.5,0.9c1.2,1.1,1.6,3,0.7,4.4
				c1.2,0.3,1.8,1.7,1.7,2.9c-0.2,1.2-1,2.2-1.8,3.2c1.3,0.4,1.8,2.2,1.2,3.4c-0.7,1.2-2.3,1.8-3.7,1.6c0.3-1.2-0.2-2.6-1.4-2.9"/>
		</g>
		<path class="rt17" d="M196.1,294.4c0,0-0.4,19.2-1.2,30.9l2.8,3.9l1.9-4.9c0,0,0.8-14.1-1.8-29.8L196.1,294.4z"/>
		<path class="rt16" d="M195.5,292.7l0.1,0.3c0.1,0.5,0.3,1,0.6,1.4l0,0l1.7,0.1c0,0,1.2-0.6,1.3-1.3c0.2-0.7-1.9-1.7-1.9-1.7
			l-0.3,0.1C196.4,291.8,195.9,292.2,195.5,292.7L195.5,292.7z"/>
		<path class="rt18" d="M204.2,286.3c0,0,1.5,1.1,2.4,2.4c-0.9,2-3.5,5.5-5.8,7.2c-1-2.8-3.5-4.4-3.5-4.4S203.6,290.2,204.2,286.3z"
			/>
		<path class="rt18" d="M197.3,291.5c0,0-2.2,0.9-3.4,3.3c0,0-0.4-3.3,0.5-6.1c0,0,1.2-2,1.7-2C195.7,289,197.3,291.5,197.3,291.5z"
			/>
		<path class="rt19" d="M195,386.5c-0.3-0.9-0.4-1.8-0.4-2.7c0,0,0.1-0.1,0.1-0.1v0c1.2-3.5,4.1-13.6,6.2-21.7l-3.3-11.7
			c0,0-2,23.8-2.6,25.5c-0.7,1.7-2.7,7.2-2.5,8.4c0.2,1.2,1.5,3.4,1.3,5.2c-0.2,1.9-0.2,18.7-0.2,18.8c0.6-4.9,1.3-10,1.7-15.2
			C195.5,390.3,195.2,387.1,195,386.5z"/>
		<g>
			<path class="rt20" d="M167.7,324.9c0.2-0.6-0.1-1.4-0.6-1.9c-0.4-0.5-1-0.8-1.7-1c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.1,0,0.2,0,0.4
				c0.2,0.4,0.7,0.6,1,1c0.1,0.1,0.2,0.3,0.2,0.5C166.5,324.4,167.1,324.8,167.7,324.9"/>
		</g>
		<path class="rt21" d="M190,310.9c0,0-2.5-6.2-2.9-8.7c-1.2,6.1-0.8,16.7-1.8,17.6c-1,0.9-11,6.6-11,6.6l0,0
			c4.7-1.5,10.5-3.6,12.2-5.6C187.3,319.7,188.7,315.6,190,310.9L190,310.9z"/>
		<path class="rt22" d="M196,283.2c0,0,4.2,0.9,5,0.8c0.8-0.1-1.6,1.3-2.1,1.3c-0.4,0.1-2.9-0.3-2.9-0.3V283.2z"/>
		<path class="rt21" d="M219.1,292.6c-0.1-0.1-0.1-0.2-0.2-0.4l-9.1,8.9c-2.2,5.7-6.5,20.9-6.5,20.9s-4.1,1.9-6.8,3.3
			c-0.7,0.4-2.7,1.7-5,3.3c0,0.7-0.1,1.2-0.1,1.5c-0.2,1.9-0.4,1.9,0.1,3.7c0,1.2-0.5,3-0.8,4.7c6.8-1.7,20.8-6.1,21.4-6.4
			c0.8-0.4,4.9-17.1,7.3-27.2C219.8,298.7,219.6,293.6,219.1,292.6z"/>
		<g>
			<polygon id="XMLID_183_" class="rt23" points="134.3,271.5 150.6,280.2 203.8,356.2 204.3,356.9 209.2,364.1 196.2,373.2 
				191,365.5 189.9,364 137.6,289.3 			"/>
			
				<rect id="XMLID_148_" x="193.4" y="364.3" transform="matrix(0.819 -0.5738 0.5738 0.819 -173.9553 181.8799)" class="rt24" width="15.9" height="4.9"/>
			
				<rect id="XMLID_151_" x="190.6" y="360.3" transform="matrix(0.8196 -0.573 0.573 0.8196 -172.0006 179.2098)" class="rt25" width="15.9" height="4.9"/>
			<polygon id="XMLID_156_" class="rt25" points="137.6,289.3 150.6,280.2 134.3,271.5 			"/>
			<polygon id="XMLID_159_" class="rt8" points="135.5,277.9 139.9,274.5 134.3,271.5 			"/>
			<polygon id="XMLID_65_" class="rt8" points="135.5,277.9 139.9,274.5 134.3,271.5 			"/>
			<polygon id="XMLID_163_" class="rt23" points="134.3,271.5 148,282.1 205.6,366.7 209.2,364.1 150.6,280.2 			"/>
		</g>
		<g>
			<g>
				<path class="rt13" d="M183.3,335.6c0,0-1.9,0.6-3,0.9c-0.7,0.2-3.8-0.1-5.6,0.3c-0.9,0.2-1.8,0.5-2.4,1.2
					c-0.6,0.7-0.6,1.9,0.1,2.4c0.2,0.1,0.5,0.3,0.5,0.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.4,0,0.8,0.3,1.1c0.3,0.3,0.8,0.3,1.1,0.1
					c0,0.8,0.7,1.6,1.6,1.6c0-0.5,0.1-1,0.5-1.2c0.4-0.3,0.8-0.2,1.3-0.3c0.8-0.1,1.5-0.4,2.1-1c0.4-0.4,0.7-0.9,1-1.3
					c0.7-0.9,1.8-1.5,2.9-1.7"/>
			</g>
			<path class="rt12" d="M209.7,300.1c-0.8,2.4-5.3,23.5-5.3,23.5s-4.3,1.1-7.1,2.5c-2.1,1.1-16.9,10.3-16.9,10.3l1.5,3.5
				c0,0,26.3-9.3,27.5-9.9c1.2-0.6,11.7-27.3,11.7-32c0-2.8-2.3-5.7-2.3-5.7"/>
		</g>
		<g>
			<path class="rt13" d="M164.9,328.4c-0.9,0.1-1.7-0.6-1.8-1.4c0-0.2,0-0.3-0.1-0.5c-0.2-0.3-0.5-0.3-0.8-0.4
				c-1.1-0.4-1.8-1.5-2-2.6c-0.1-0.6-0.1-1.2,0.1-1.8c0.2-0.6,0.7-1.1,1.3-1.2c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2
				c0.3,0.5,0.6,1,0.7,1.5c0.1,0.4,0.2,0.9,0.5,1.1c0.2,0.2,0.5,0.2,0.8,0.3c0.5,0.3,0.7,0.8,0.9,1.4
				C165.2,325.7,164.8,327.5,164.9,328.4"/>
		</g>
	</g>
	<g>
		<path class="rt17" d="M246.6,272.9h25c0.6,0,1,0.4,1.2,0.9l3.9,14.4l7.6,4h-27.6l-7.6-4l-3.8-13.7
			C245.2,273.7,245.8,272.9,246.6,272.9z"/>
		<path class="rt15" d="M249.6,287.9h27l-3.9-14.1c-0.1-0.5-0.6-0.9-1.2-0.9h-24.5c-0.8,0-1.4,0.8-1.2,1.5L249.6,287.9z"/>
		<path class="rt6" d="M246.5,274.3l0.5,1.7v0l2.8,10.3c0.1,0.6,0.7,0.9,1.2,0.9h23.1c0.8,0,1.4-0.8,1.2-1.6l-0.2-0.6l-0.5-1.9
			l-0.5-2l-1.3-4.8l-0.5-1.8c-0.1-0.6-0.7-0.9-1.2-0.9h-24c-0.2,0-0.3,0.1-0.4,0.2C246.5,273.9,246.5,274.1,246.5,274.3z"/>
		<polygon class="rt15" points="257,291.8 284.1,291.8 276.6,287.9 249.6,287.9 		"/>
		<path class="rt2" d="M314.8,391.1c0,1-9.5,1.9-21.3,1.9c-11.7,0-21.3-0.8-21.3-1.9c0-1,9.5-1.9,21.3-1.9
			C305.3,389.2,314.8,390,314.8,391.1z"/>
		<g>
			<path class="rt13" d="M300.3,382.9c0,0,0.1,3.6,0.2,4.5c0.1,0.9,3,1,3.3,0.5c0.3-0.5,0.5-4.7,0.5-4.7"/>
			<path class="rt14" d="M304.5,388.7c0,0.9-0.3,2.5-0.3,2.5h-3.3l-0.2-0.6c0,0-1.1,0.6-1.9,0.6H296c-2.1,0-3.2-0.3-3.3-0.6
				c-0.2-1.5,2.2-1.5,2.9-1.5c1.8,0,3.8-0.9,4.3-1.8c0.3-0.7,0.2-0.9,0.6-0.7c0,0,0.3,0.1,0.6,0.5c0.4,0.6,1,1.1,1.7,1
				c0.4-0.1,0.8-0.2,1.1-0.5C303.8,387.5,304.5,387.7,304.5,388.7z"/>
		</g>
		<g>
			<path class="rt13" d="M288.5,381.9c0,0,0.6,2.4,1,3.3c0.4,0.8,3.1-0.2,3.3-0.8c0.1-0.6-1.3-4.5-1.3-4.5"/>
			<path class="rt14" d="M293.8,384.9c0.4,0.9,0.9,2.6,0.9,2.6l-2.5,1.5l-0.5-0.4c0,0-0.6,0.9-1.3,1.4c-0.6,0.4-1.8,0.9-2.5,1
				c-2.3,0.5-4.1,0.3-4.3,0c-0.8-1.3,2.3-2.1,2.9-2.3c1.7-0.7,2.5-2.3,2.6-3.4c0-0.7-0.2-0.9,0.3-0.9c0,0,0.3-0.1,0.7,0.2
				c0.6,0.5,1.3,0.6,2,0.2c0.4-0.2,0.7-0.5,0.8-0.9C292.8,384,293.5,384,293.8,384.9z"/>
		</g>
		<path class="rt23" d="M306.4,300.8c1.2-0.9,2.5-1.9,3.8-2.9c2.8-2.4,5.4-5,5.7-6.8c0.7-4.2,0.2-16.8-0.2-22.7c0-0.4,0-0.7-0.1-1
			c-0.3-5.6-3.3-8.1-4.8-9c-0.8,7.3-2.5,23.7-2.6,24.5C307.9,283.9,306.4,300.8,306.4,300.8z"/>
		<path class="rt9" d="M306.4,300.8c1.2-0.9,2.5-1.9,3.8-2.9c0.1-4.6,1.5-11.1,3.4-19.2c2.3-10.2,0.9-12.1,0.9-12.1l-4.6,0.7
			c-0.8,7.2-1.6,15-1.7,15.5C307.9,283.9,306.4,300.8,306.4,300.8z"/>
		<path class="rt15" d="M295.3,339c0.3,5.2,0.7,10.5,1.4,14.2c0.6,3.2,10.5,3.7,9.3,1.3c-1.8-3.5,1.4-14.6,2.9-33.5
			c0.2-2.9,1.5-3.2,2.4-9.1c0.8-5.9-2.4-11-2.4-11l-13.8-1.3l-0.2,17.1l-0.1,5.8C294.9,322.7,294.9,330.7,295.3,339z"/>
		<path class="rt15" d="M304.8,352.4c0,0,2.7,3.3,2.3,9.3c-0.4,6-2.5,24.1-2.5,24.1s-3.2,0.5-4.8-0.1c0,0-3.4-33.5-3.4-37.3
			C296.4,344.6,304.8,352.4,304.8,352.4z"/>
		<path class="rt19" d="M295.3,339c0.9-2.8,2.6-8.1,4-11.1c2-4.2,3.9-6,3.9-6c7-7.3,2.2-16.8,2.2-16.8s-5.7,4.7-6.1,5.2
			c-0.4,0.5-2.6,4.5-4.3,6.6l-0.1,5.8C294.9,322.7,294.9,330.7,295.3,339z"/>
		<path class="rt15" d="M279,336.8c0,0-3.1,10.3-2.6,13.5c0.5,3.2,11.9,33.3,11.9,33.3s2.1,0,4.6-1.1c0,0-0.2-13.2-2.5-21.5
			c-2.2-8.3-4.8-13.4-7.1-14.1C281,346.1,279.6,338.5,279,336.8z"/>
		<path class="rt15" d="M289.1,342.9c0,0-3.4,5.4-1.3,10.2c2.1,4.8-3.5,4-3.5,4s-2.8-8.1-2.2-9.2c0.6-1.1,2.9-4.6,3.7-4.8
			C286.5,343,289.1,342.9,289.1,342.9z"/>
		<path class="rt15" d="M285.6,300.1c0,0-7.2,33.5-8.2,42.5c-0.4,3.8,3.6,10.2,7.3,6.7c4.4-4.1,11.6-23,13.8-26.4
			c2.2-3.3,10.6-6.4,4.9-23.4"/>
		<path class="rt13" d="M303.5,255.1c0,0-1-3.4-0.6-5.8c0.4-2.5,1.9-6.9,1.4-10.3s-4.8-6.1-9.2-5.7c-4.5,0.4-5.8,4.7-6.5,7.2
			c-0.7,2.5-0.6,6.2-0.4,8.3c0.2,2,1.3,3.7,2,4.1c0.7,0.4,3.5-0.1,4,0.4c0.5,0.5,0.9,3.7,0.9,3.7"/>
		<path class="rt7" d="M305.6,257.7c0,0-0.2-2-1-2.6s-5.7-0.7-7.2-0.5c-1.5,0.2-5.1,1.9-5.6,2.3c-0.5,0.4,0.5,2.2,0.5,2.2
			L305.6,257.7z"/>
		<path class="rt17" d="M303.3,250.8c0,0-6.2,0.5-7.4,0c-1.2-0.5-2.9-1.7-3-3.8c-0.2-2.1,0.3-2.8-0.5-3.9c-0.8-1.2-1.5-0.8-1.6,0.3
			c-0.1,1.1-0.2,3.1-0.5,3.3c-0.4,0.2-0.9-1.2-0.7-3.4c0.1-1.9,0.3-3.3,0.9-4.5c0.6-1.2,0.9-1.2,1.4-1.4c-0.5-0.3-1.1-0.3-2.2-2
			c-1.2-1.9-0.6-4.9,2.7-4.9c5.7,0,11.8,1.1,11.9,5.4c0,0,2-0.3,2.1,3.6c0.1,3.9-2.4,8.5-2.6,9.7
			C303.6,250.4,303.9,250.6,303.3,250.8z"/>
		<path class="rt23" d="M286.1,296.3c-0.2,1.9-1.9,4.9-0.8,5.4c0.5,0.2,2.4,0.1,4.7-0.1h0.1c0,0,0.1,0,0.1,0
			c2.1-0.2,4.4-0.4,6.4-0.6c7.9-0.3,11.9,1.2,12.7,0.8c0.4-0.2,0.4-0.9,0.3-1.6c-0.3-1.7-0.4-3.5-0.1-5.2c0.2-1.5,0.5-3.2,0.8-5.2
			c1.3-6.9,3.8-17.4,4.2-23.8c0.6-9.9-10-9-10-9c-13.5-0.8-19.9,3.1-20.6,8.9c-0.1,0.4-0.1,0.9-0.1,1.3c-0.3,6,1.5,15.7,2.3,22.9
			C286.3,292.1,286.2,294.4,286.1,296.3z"/>
		<path class="rt9" d="M286.1,296.3c-0.2,1.9-1.9,4.9-0.8,5.4c0.5,0.2,2.4,0.1,4.7-0.1c-2.1,0.2-2.5-1.4-2-2.5
			c0.5-1.1,1.4-3.5,1.3-6.4c-0.1-2.9-1.2-9.4-1.8-15.5c-0.6-5.7-0.1-10.2,0-10.8c-0.3,0.6-1.3,1.6-3.9,2.2c-0.1,6,1.6,14.9,2.4,21.6
			C286.3,292.1,286.2,294.4,286.1,296.3z"/>
		<path class="rt13" d="M251.6,292.6c-0.3-0.2,0-0.5,0.3-0.5c0,0,0.1,0,0.3,0c-0.6-0.2-1-0.3-1.1-0.4c-0.3-0.2,0-0.5,0.3-0.5
			c-0.2-0.1-0.4-0.1-0.4-0.2c-0.4-0.2,0-0.6,0.3-0.5c0.1,0,0.3,0.1,0.6,0.1c-0.1-0.1-0.3-0.1-0.3-0.1c-0.3-0.2-0.1-0.5,0.2-0.5
			c0.1,0,0.3,0,0.7,0.1c0.3,0.1,0.7,0.1,1.1,0.2c0.5,0.1,1,0.2,1,0.2c1.2-0.4,1.8-1,1.8-1l-1.2-0.7c0,0-0.1-1,3.2,0.2
			c0.5,0.2,0.9,0.9,1.2,0.9c0.3,0.1,1.2,0.1,1.2,0.1l0,2.9c0,0-1.6-0.3-2.1-0.1c-0.5,0.2-2.4,0.8-2.7,0.8c-0.1,0-0.7,0-1.4-0.1
			l-1-0.2l-0.9-0.3C252.7,293.1,251.7,292.7,251.6,292.6z"/>
		<path class="rt23" d="M260.1,293.2c0,0,5.2,1.2,9.3,1.9c3.2,0.6,6.7,1.1,10.1,0.8c0,0,0.1,0,0.1,0c2.1-0.1,4.9-0.7,5.5-1.6
			c0.6-0.9,1.9-4.6,1.9-4.6c1.1-2.2,2.7-8.1,4-13.2c0-0.1,0-0.2,0.1-0.2c0.6-2.4,1.2-4.5,1.6-6c0.2-0.7,0.2-1.4-0.1-2
			c-0.2-0.6-0.6-1.1-1.1-1.4c0,0,0,0,0,0c-1.4-1-1-2.3-2.4-1.4c-0.1,0-1-0.2-1-0.2c-0.9-2.3-0.3-5.4-0.3-5.4s-4.1,1.8-5.6,10.2
			c-0.3,1.9-2.4,15.1-2.5,16.7c0,0.1,0,0.2,0,0.4c0,0-0.1,0.2-0.1,0.5c0,0.1,0,0.2,0,0.3c-1.2,0-2.6,0.1-4,0.4
			c-4.7,1.1-15.1,1.6-15.1,1.6l-0.3,2.6l0,0l0,0L260.1,293.2z"/>
		<g class="rt26">
			<polygon class="rt0" points="266.2,273.5 274.1,281.1 272.8,276.3 269.9,273.5 			"/>
			<polygon class="rt0" points="275.2,285 263.2,273.5 264.7,273.5 274.6,283.1 			"/>
			<polygon class="rt0" points="250.9,273.5 265.1,287.2 266.5,287.2 252.4,273.5 			"/>
			<path class="rt0" d="M246.5,274.3l0.5,1.7v0l11.6,11.2h4l-14.2-13.7h-1.4c-0.2,0-0.3,0.1-0.4,0.2
				C246.5,273.9,246.5,274.1,246.5,274.3z"/>
		</g>
	</g>
	<path class="rt1" d="M311.2,230.1c-0.6-1.6-0.7-3.5-0.1-5.2c1.4-4.2,5.8-6.4,10-5.1c4.2,1.4,6.4,5.8,5.1,10
		c-1.4,4.2-5.8,6.4-10,5.1c-1.5-0.5-2.8-1.4-3.8-2.6l-3.4,0.6L311.2,230.1z"/>
	<ellipse class="rt2" cx="61.3" cy="389.3" rx="34.9" ry="2.4"/>
	<path class="rt1" d="M34,220.2c1.1-1.4,1.7-3.1,1.7-4.9c0-4.4-3.5-7.9-7.9-7.9c-4.4,0-7.9,3.5-7.9,7.9c0,4.4,3.5,7.9,7.9,7.9
		c1.6,0,3.1-0.5,4.4-1.3l3.1,1.6L34,220.2z"/>
	<g>
		<path class="rt17" d="M40.7,262.7l-2.8,10.9c0,0,10.5,8.1,14.6,4.7c4.1-3.4-0.1-13.8-0.1-13.8L51,263L40.7,262.7z"/>
		<path class="rt17" d="M60.3,226.6c0,0,3,3.5,2,10.7c-0.3,2.1,2.4,5.1,4.3,6.5c1.8,1.5,3.5,5,2.1,8.3c-1.5,3.3-2.1,3.6-0.9,6.7
			c2.2,5.8,0.9,16.4-7.4,15.6c-8.3-0.9-9-29.3-9-29.3l0.5-9.5L60.3,226.6z"/>
		<g>
			<path class="rt13" d="M57.5,255.8c0,0,3,0.4,6.8,0.9c5.7,0.7,13.3,1.3,15.2,0.3c3.2-1.6,13.6-10.5,13.6-10.5l1.6-1.1
				c0.3-0.2,0.6-0.3,0.9-0.3c0.3,0,0.9-0.1,1.7-0.3c0.4-0.1,0.8-0.3,1.1-0.5c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0
				c0.7-0.5,1.3-1,1.7-1.4c0.7-0.6-0.4-1-1.6-0.4c-1.2,0.6-0.4,0.5-1.2,0.4c-0.8,0,0-1.2,0.8-2c0.6-0.8,0.6-0.8,1.2-1.7
				c0.6-0.9,1.2-1.6,1.6-2.1c0.2-0.4,0.3-0.9-0.1-0.8c-0.5,0.1-2.5,2.1-3,2.5c-0.5,0.3-1.2-0.3-2.7,0.5c-1.4,0.8-2,3.1-2.4,3.8
				c-0.4,0.7-14.9,9.2-16.6,9.1c-1.8-0.1-15.3-3.3-19.5-3.1C54.5,249.2,57.5,255.8,57.5,255.8z"/>
			<path class="rt22" d="M64.3,256.7c5.7,0.7,13.3,1.3,15.2,0.3c3.2-1.6,13.6-10.5,13.6-10.5l1.6-1.1c0.3-0.2,0.6-0.3,0.9-0.3
				c0.3,0,0.9-0.1,1.7-0.3c0.4-0.1,0.8-0.3,1.1-0.5c-1,0.5-3,0.1-3.9,0.6c-1.1,0.5-8.4,6.5-11.7,8.6c-3.3,2.1-4.6,2.8-7.5,2.8
				c-3,0-10-1.3-10-1.3L64.3,256.7z"/>
		</g>
		<path class="rt27" d="M61.6,378.4c0,0,0.6,3,0.4,5.3c-0.3,1.6-0.1,2.1-0.1,2.1l6.2-0.4c0,0-2.7-1.4-2.3-7.8
			C66.3,371.4,61.6,378.4,61.6,378.4z"/>
		<path class="rt14" d="M62,384.2c0,0,2.6,1.1,5.3,0.4c3.1,1.5,5.2,2.2,8.1,2.6c2.8,0.4,2.3,2.8-2.6,2.3c-4.8-0.5-8.2-0.3-9.3-0.3
			c-1.1,0.1-2.8,0.2-2.9-0.6C60.6,387.9,61.1,383.9,62,384.2z"/>
		<path class="rt27" d="M42.7,377.9c0,0,0.6,3,0.4,5.3c-0.3,1.6-0.1,2.1-0.1,2.1l6.2-0.4c0,0-2.7-1.4-2.3-7.8
			C47.4,370.8,42.7,377.9,42.7,377.9z"/>
		<path class="rt14" d="M43.1,383.7c0,0,2.6,1.1,5.3,0.4c3.1,1.5,5.2,2.2,8.1,2.6c2.8,0.4,2.3,2.8-2.6,2.3c-4.8-0.5-8.2-0.3-9.3-0.3
			c-1.1,0.1-2.8,0.2-2.9-0.6C41.7,387.4,42.2,383.4,43.1,383.7z"/>
		<path class="rt28" d="M66.5,289.9c0,0,2.8,41.6,2.9,47c0.1,5.4,0,7.3-0.3,10.6c-0.3,3.3,0.9,31.1,0.9,31.1s-9.3,2.1-11.6-0.1
			c0,0,1.2-30.2,0.2-35.4c-1-5.2-4.9-31.5-4.9-31.5l6-26.6L66.5,289.9z"/>
		<path class="rt15" d="M45.1,339.3c1.2-7.7,0.3-39.2,0.6-45.9c0.3-6.7,18.9-2.7,18.9-2.7s-7,38.8-7.8,44.5
			c-0.9,5.6-1.8,7.2-2.4,12.6c-0.5,5.4-3.8,30.7-3.8,30.7S40.2,380,39.5,378C38.2,367.9,45.1,339.3,45.1,339.3z"/>
		<path class="rt29" d="M62.6,262.7c-0.2,0.5,0.2,4.1,0.9,8.6c0,0.2,0.1,0.4,0.1,0.6c0.8,5.6,2,12.5,2.7,16.8c0,0.2,0.1,0.4,0.1,0.6
			c0.2,1.4,0.4,2.4,0.5,3.1c0,0-12.4-2.7-22.4,1.5c0,0,0.8-6.2,3-12c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.9-2.1,1.4-3.1
			c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0.1-0.1,0.1-0.1c0.6-1.7,0.4-3.8-0.4-6.2c-0.7-2.2-1.8-4.4-3-6.6
			c-0.7-1.3-1.5-2.5-2.2-3.6l0,0c-2.1-3.1-3.9-5.3-3.9-5.3s-1.2-4.1,1.2-7.7c0,0,0,0,0,0c0.3-0.4,0.6-0.8,1-1.2
			c1.1-1.1,4-2.4,6.4-3.2c0.6-0.2,1.2-0.4,1.7-0.5c0.4-0.1,0.8-0.2,1-0.2c1.4-0.1,2.5-0.1,3.3,0.1c2.6,0.6,3.5,2.3,7.4,3.5l8.4,2.5
			c0,0-1.1,7.1-0.3,9.2l-5.9-0.2C64.3,258.2,63.2,261.3,62.6,262.7z"/>
		<path class="rt9" d="M49.4,277.5c1.5-4.2-2.4-11.3-5.7-16.3l0,0l3.6-3.8c0,0-0.1,6.3,1,8.7C49.5,268.5,51.2,273.5,49.4,277.5z"/>
		<path class="rt9" d="M62.6,252.4c0,0,2.4,4,1.7,5.9c5.9,0.2,5.9,0.2,5.9,0.2s-0.4-0.9-0.3-2.6C69,256,65.4,256.9,62.6,252.4z"/>
		<g>
			<path class="rt17" d="M57.7,289.6l23.4-0.9c0.6,0,1-0.4,1.1-1l3.2-15.8c0.2-0.8-0.4-1.5-1.2-1.5l-23.4,0.9c-0.6,0-1,0.4-1.1,1
				l-3.2,15.8C56.3,289,56.9,289.7,57.7,289.6z"/>
			<path class="rt5" d="M57.6,289.1l23.2-0.9c0.3,0,0.6-0.3,0.7-0.6l3.2-15.6c0.1-0.5-0.3-0.9-0.7-0.9L61.3,272
				c-0.5,0-1,0.4-1.1,0.9L57,288.4C57,288.8,57.2,289.1,57.6,289.1z"/>
			<path class="rt13" d="M55.8,281.1l-0.2,1l-0.5-0.1l-0.3,1.4c0,0-0.4-0.1-1.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0
				c-1.2-0.2-3.3-0.6-6.2-1.3c-1.8-0.4-4-0.9-6.4-1.6c-3.2-0.8-5.2-2.4-5.3-2.9c-0.1-0.7,0-2.1,0.3-3.8c0.1-0.9,0.3-1.8,0.4-2.8
				c0.6-3.5,1.4-7.2,1.6-8.6c0-0.1,0.1-0.3,0.1-0.3c0-0.1,0-0.2,0-0.2l0.9,0.4l5,1.9l-0.3,0.9l-3.1,8.9c0,0,0,0.1,0,0.1
				c0,0.3,0.1,0.5,0.4,0.6c2.5,0.9,5.4,2.3,8,3.6c2.2,1.1,4.1,2.1,5.2,2.7c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.5,0.2,0.8,0.2
				C55.5,281.2,55.8,281.1,55.8,281.1z"/>
			<path class="rt22" d="M53.7,283.2c-1.2-0.2-3.3-0.6-6.2-1.3c-1.8-0.4-4-0.9-6.4-1.6c-3.2-0.8-5.2-2.4-5.3-2.9
				c-0.1-0.7,0-2.1,0.3-3.8c0.6-4,1.7-9.7,2.1-11.4c0-0.1,0-0.3,0.1-0.3l1.3,0.6c0,0,0,0.2-0.1,0.6c-0.4,1.8-1.6,7.4-2.2,10.7
				c-0.2,1.1-0.3,1.9-0.3,2.3c0.1,1.6,1,1.8,2.1,2.3c0.6,0.3,4.9,1.7,8.7,2.9C50.6,282.3,53.2,283.1,53.7,283.2z"/>
			<path class="rt13" d="M54.3,280.9c0,0,0.6,0.1,0.9,0.1c0.3,0,0.4-0.1,0.4-0.1s1.4-0.9,2.5-1.1l-0.2,1c0,0,1.6,0.9,2.1,1.2
				c0,0,0.5,0.2,0.7,0.3c0.2,0.1,2.1-0.1,2.5-0.1c0.4,0,0.7,0.3,0.7,0.5c0,0,0,0.1-0.3,0.2c0,0-0.2,0-0.3,0c0.2,0,0.4,0,0.5,0
				c0.4,0,0.7,0.3,0.7,0.5c0,0,0,0.1-0.3,0.2c-0.1,0-0.4,0.1-0.8,0.1c0.3,0.1,0.5,0.3,0.5,0.5c0,0,0,0.1-0.3,0.2
				c-0.1,0-0.4,0.1-0.9,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0.1-0.3,0.2c-0.2,0-3.5,0.6-3.8,0.5c-0.6-0.1-2.9-1.8-3-1.9
				c-0.4-0.3-1-0.5-1-0.5L54.3,280.9z"/>
		</g>
		<path class="rt29" d="M48.1,257c-1.3,4.5-1.9,6.9-2.1,7.8c-0.1,0.3-0.1,0.4-0.1,0.4s-0.8,0-2-0.2c-1.3-0.3-2.9-0.8-4.5-1.8
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.7c-0.5-0.4-0.9-0.9-1.3-1.5c0,0,1.3-8.8,4.3-12.6c0,0,0,0,0,0
			c0.5-0.7,1.1-1.2,1.8-1.5c0.1,0,0.2-0.1,0.3-0.1c1.5-0.5,2.8,0,3.8,1.2c0.9,1.1,1.4,2.6,1.6,4.5C48.7,253.8,48.6,255.4,48.1,257z"
			/>
		<path class="rt13" d="M57.9,240.9c-1.1,0.6-2.2,0.8-3.4,0.8c0,0.5,0,1,0.1,1.5c0.3,0.1,0.4,0.1,0.6,0.2c0.4,0.2,0.2,1,0.2,1.3
			c-0.2,1.5-1.6,2.1-3,1.7c-1.3-0.4-1.9-1.9-2.3-3.1c-0.2-0.5-1.2-1-1.2-1.9c0-0.3,0-0.6,0-0.9c-0.6-0.2-1-0.4-1-0.4
			s-0.1-1.5-0.2-3.6c0-0.6,0-1.2,0-1.9c0-3,0.3-6.4,1.4-8.1c1.3-2.1,4.8-1.8,7.2-1.2c1.5,0.3,2.5,0.8,2.5,0.8S62.1,238.6,57.9,240.9
			z"/>
		<path class="rt30" d="M54.7,227.6c0,0,0.4,2.4-1.4,3.9c-1.9,1.5-5,2.3-5.1,4.2c0,1.2,0.9,2.2,0.9,2.2s-2.1-0.1-2.2-0.2
			c-0.1-0.1-1.1-4.6-1.1-4.6s4.2-4.9,4.8-5.2C51,227.6,54.7,227.6,54.7,227.6z"/>
		<path class="rt17" d="M56.4,225.3c-2.4-0.6-5.9-0.9-7.2,1.2c-1.1,1.7-1.4,5.1-1.4,8.1c-0.3,0.4-0.4,1.1,0,1.9
			c0,2.1,0.2,3.6,0.2,3.6s0.3,0.2,1,0.4c0,0.3,0,0.6,0,0.9c0,0.9,0.1,1.7,0.1,2.3c-2.2,0.7-4.8,1.9-5.8,2.9c-0.1,0-0.2,0.1-0.3,0.1
			c-0.7,0.3-1.3,0.8-1.8,1.5c0,0,0,0,0,0c-2.9,3.8-4.3,12.6-4.3,12.6c0.4,0.5,0.9,1,1.3,1.5c-0.3,1.3-1.1,5.1-1.6,8.6
			c-1-0.4-2.1-1-3-1.8c-3.4-2.7-4.1-8.1-1.6-11.7c1.9-2.7,1.8-4.5,1.6-5.1c-2.6-8.1,1-13.9,5.2-16.4c1.7-1,2.8-2.9,2.8-5
			c0-2.3,0.4-5,2.1-6.9c2.4-2.8,5.2-3.5,7.6-3.2c2,0.3,4.4,1.7,5.5,3.5C56.9,224.6,56.7,224.9,56.4,225.3z"/>
		<path class="rt17" d="M50.9,242.4c0,0.3-0.1,0.6-0.2,0.8c-0.5,0.1-1,0.3-1.6,0.5c0-0.5-0.1-1.3-0.1-2.3c0-0.3,0-0.6,0-0.9
			c-0.6-0.2-1-0.4-1-0.4s-0.1-1.5-0.2-3.6c0.1,0.3,0.4,0.6,0.7,0.9C48.5,237.4,51,239.5,50.9,242.4z"/>
		<path class="rt17" d="M56.4,225.3c-0.8,0.8-2.4,1.7-2.3,2.9c0.1,3.2-3.2,4.6-5.7,5.9c-0.2,0.1-0.4,0.3-0.6,0.5
			c0-3,0.3-6.4,1.4-8.1C50.5,224.3,54,224.7,56.4,225.3z"/>
		<path class="rt17" d="M61.3,222.1c1.8,1.3,1.8,4.1,0.1,5.5c-1.7,1.4-4.6,2.3-9,0.2c-5.4-2.5-4-7-0.5-6.9
			C58.6,222.9,59.3,220.5,61.3,222.1z"/>
	</g>
	<path class="rt17" d="M50.6,240.7c0,0,2.6,7.3-9,5.8c-9.7-1.2-8.1,12.4-8.1,12.4l3.7-8.2l4.5-6l2.4-2.7L50.6,240.7z"/>
</g>
<g id="DESIGNED_BY_FREEPIK">
</g>
</svg>

</b-col>
    </b-row>
    
  </b-container>
</template>

<script>
  

  export default {
    computed: {
      
    },
    components: {
     
    },
    data() {
      return {}
    },
  }
</script>
<style>
.rt0{fill:#EFEFFF;}
	.rt1{fill:#B2AAF2;}
	.rt2{opacity:0.4;fill:#B0AFFD;}
	.rt3{fill:#D2D2FF;}
	.rt4{opacity:0.5;fill:#D2D2FF;}
	.rt5{fill:#5148A5;}
	.rt6{fill:#B0AFFD;}
	.rt7{fill:#FFFFFF;}
	.rt8{fill:#FFB127;}
	.rt9{opacity:0.5;fill:#FFB127;}
	.rt10{opacity:0.3;}
	.rt11{fill:none;stroke:#B0AFFD;stroke-width:1.1106;stroke-miterlimit:10;}
	.rt12{fill:#7762EF;}
	.rt13{fill:#ED9C80;}
	.rt14{fill:#3A3A3A;}
	.rt15{fill:#2D24A3;}
	.rt16{fill:#170D54;}
	.rt17{fill:#2C1E56;}
	.rt18{fill:#EAEAEA;}
	.rt19{opacity:0.5;fill:#3D38A0;}
	.rt20{fill:#FFBE9D;}
	.rt21{opacity:0.5;fill:#5F52DD;}
	.rt22{opacity:0.3;fill:#ED9C80;}
	.rt23{fill:#FFC25B;}
	.rt24{fill:#FE7F9A;}
	.rt25{fill:#FFE7C5;}
	.rt26{opacity:0.1;}
	.rt27{fill:#FFCC99;}
	.rt28{fill:#201D93;}
	.rt29{fill:#FFC064;}
	.rt30{fill:#D8836C;}
</style>