<template>
  <b-container fluid>
    <b-row align="center" align-h="center">
		<b-col cols="7">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2157.3 1694" style="enable-background:new 0 0 2157.3 1694;" xml:space="preserve">

<path class="et0" d="M184.6,1492.3c0,0-252.7-289.5-147.2-629.8C132.1,557,560.3,359.4,787.8,510.6c110.1,73.2,199-221,564.3-328.6
	c270.2-79.6,669.6,99,568.2,645.8c-77.1,415.8,305.5,208.5,221.9,667.8L184.6,1492.3z"/>
<g>
	<path class="et1" d="M1523.5,451.7c0,0-54.9-59.4-7-100.2c0,0,35.2-23.2,64.7-22.5c0,0-39,71.4-25.3,77.9
		c13.7,6.4,37.8-82.7,37.8-82.7s81.8-11,108.6,0.7c0,0-68,60-53.1,62.9c14.9,2.9,88.6-56.3,88.6-56.3s76.9,10.1,74.5,56.9
		c-2.3,46.7-28.1,62.1-39.7,61.3c-11.6-0.8-80.2-24.1-81.4-14.6c-1.1,9.5,40.8,39,69.3,39.4c0,0-51.6,61.4-88,45.6
		c-36.4-15.8-40.5-42.8-63.2-52.2c-22.7-9.4-41.7-8.6-32.1,1.4c9.6,10,50.4,18.6,64,40.7c13.6,22.1,25.6,36.9-11.6,32.7
		c-37.2-4.2-98.4-29-102.2-59.9C1523.5,451.7,1523.5,451.7,1523.5,451.7z"/>
	<g>
		<path class="et1" d="M1774.8,390.8c0,0-207.7-0.2-251.3,60.8c0,0-9,30.7-39.1,59.5l-4.4,13.4c0,0,34.5-39.3,47.3-42
			C1527.3,482.6,1504.5,409.8,1774.8,390.8z"/>
		<path class="et1" d="M1484.4,511.2c0,0-43.3,34.6-44.2,93.6l8.1,0.4c0,0,11.9-61.4,36.7-86.1
			C1509.9,494.3,1484.4,511.2,1484.4,511.2z"/>
	</g>
</g>
<path class="et2" d="M1399.4,380.9c0,0-123.4-27.8-97.9-122.9c0,0,24.8-61.1,63.4-86.4c0,0,13,126.6,36.4,122.8
	c23.4-3.9-24.7-140.1-24.7-140.1s95.6-86.7,140.6-95.5c0,0-34.4,137.6-12.6,128.1c21.7-9.5,64.2-151.1,64.2-151.1
	s108-55.1,146.5,7.2c38.5,62.3,18.9,105,3.2,114.2c-15.7,9.3-124.7,40.1-117.7,53.4c7,13.3,87.1,14.1,124.2-10.8
	c0,0-12.1,124.9-73,136.8c-60.9,12-90.2-19.3-127.7-11.2c-37.5,8.1-61.4,25.9-40.1,30.3c21.3,4.4,81.4-20.6,118.5-4.2
	c37.1,16.4,65.7,24.8,14,52.4c-51.7,27.6-152.6,49.9-184.9,13.4C1399.4,380.9,1399.4,380.9,1399.4,380.9z"/>
<g>
	<path class="et1" d="M1669.3,79.6c0,0-267.8,183.9-269.9,301.2c0,0,15.7,47.6,2.4,111.4l6.3,21.2c0,0,9.7-81.2,23.7-96.1
		C1431.7,417.3,1337.8,343.9,1669.3,79.6z"/>
	<path class="et1" d="M1401.8,492.3c0,0-25.1,83,26,159.7l10.8-6.6c0,0-39-89.7-29-143.5C1419.6,447.9,1401.8,492.3,1401.8,492.3z"
		/>
</g>
<path class="et2" d="M713.7,1089.1c0,0,123.4-27.8,97.9-122.9c0,0-24.8-61.1-63.4-86.4c0,0-13,126.6-36.4,122.8
	c-23.4-3.9,24.7-140.1,24.7-140.1s-95.6-86.7-140.6-95.5c0,0,34.4,137.6,12.6,128.1c-21.7-9.5-64.2-151.1-64.2-151.1
	s-108-55.1-146.5,7.2c-38.5,62.3-18.9,104.9-3.2,114.2c15.7,9.3,124.7,40.1,117.7,53.4c-7,13.3-87.1,14.1-124.2-10.8
	c0,0,12.1,124.9,73,136.8c60.9,12,90.2-19.3,127.7-11.2c37.5,8.1,61.4,25.9,40.1,30.3c-21.3,4.4-81.4-20.6-118.5-4.2
	c-37.1,16.4-65.7,24.8-14,52.4c51.7,27.6,152.6,49.9,184.9,13.4C713.7,1089.1,713.7,1089.1,713.7,1089.1z"/>
<g>
	<path class="et1" d="M443.9,787.9c0,0,267.8,183.9,269.9,301.2c0,0-15.7,47.6-2.4,111.4l-6.3,21.2c0,0-9.7-81.2-23.7-96.1
		C681.4,1125.6,775.4,1052.1,443.9,787.9z"/>
	<path class="et1" d="M711.4,1200.5c0,0,25.1,83-26,159.7l-10.8-6.6c0,0,39-89.7,29-143.5C693.5,1156.1,711.4,1200.5,711.4,1200.5z"
		/>
</g>
<rect x="633.8" y="234.2" class="et3" width="898.3" height="1261.4"/>
<rect x="665.4" y="269.1" class="et4" width="835.1" height="1203.1"/>
<rect x="751.4" y="416.7" class="et5" width="681.8" height="39"/>
<g>
	<rect x="751.4" y="654.7" class="et5" width="681.8" height="17.9"/>
	<rect x="751.4" y="696.8" class="et5" width="681.8" height="17.9"/>
	<rect x="751.4" y="738.9" class="et5" width="681.8" height="17.9"/>
	<rect x="751.4" y="781" class="et5" width="681.8" height="17.9"/>
	<rect x="751.4" y="823.1" class="et5" width="681.8" height="17.9"/>
	<rect x="751.4" y="865.2" class="et5" width="681.8" height="17.9"/>
</g>
<g>
	<path class="et5" d="M951,1109.6c0,29.1-23.6,52.6-52.6,52.6c-29.1,0-52.6-23.6-52.6-52.6s23.6-52.6,52.6-52.6
		C927.5,1057,951,1080.5,951,1109.6z"/>
	<path class="et5" d="M1171.3,1160.2c-0.1,0-0.3,0-0.4,0c-1.4-0.2-2.4-1.6-2.2-3c0.5-2.9,12.5-71,50.2-94.1c2.7-1.6,4.4-0.8,5.1-0.1
		c3.3,2.8,0.8,10.1-10.6,37.3c-4.5,10.9-9.9,23.7-12.1,31.6c4-5.3,10-14.3,14.8-21.5c14.9-22.4,17.8-26.1,20.8-26.1
		c1.1,0,2,0.5,2.7,1.4c1.6,2.3,0.4,6.1-3.7,17.3c-1.6,4.3-4,10.8-4.7,14.4c2.1-1.9,5.3-6,7.2-8.5c5.2-6.7,7.8-9.9,10.9-9.5
		c0.6,0.1,2.2,0.6,3,2.6c0.3,0.9,0.5,1.8,0.6,2.9c0.4,2.7,0.9,5.8,4.1,7.6c6.6,3.7,25.4,3.5,78.2-18.5c1.3-0.6,2.9,0.1,3.4,1.4
		c0.6,1.3-0.1,2.9-1.4,3.4c-44.5,18.6-71.6,24.5-82.8,18.2c-5.4-3.1-6.2-8.2-6.7-11.3c-1.5,1.6-3.6,4.3-5.1,6.3
		c-5.7,7.4-9.5,11.9-13.3,11.6c-1.2-0.1-2.2-0.7-2.8-1.7c-1.8-2.9,0-8.5,4.5-20.8c0.7-2,1.6-4.2,2.3-6.3c-3.7,5-8.6,12.4-12.6,18.5
		c-16.7,25.1-19.7,28.7-23.1,27.8c-0.5-0.1-2.2-0.8-2.4-3.2c-0.3-5.9,5.1-19.5,13.5-39.6c4.3-10.2,9.8-23.6,11.2-29.5
		c-34.3,23.1-45.9,88.7-46,89.4C1173.7,1159.3,1172.6,1160.2,1171.3,1160.2z M1229.5,1118.2L1229.5,1118.2L1229.5,1118.2z"/>
</g>
<path class="et6" d="M1279.7,213.7h-105.9c2.4-6.3,3.7-13.1,3.7-20.2c0-31.5-25.5-57-57-57s-57,25.5-57,57c0,7.1,1.3,13.9,3.7,20.2
	H961.3c-8.5,0-15.4,6.9-15.4,15.4V272c0,8.5,6.9,15.4,15.4,15.4h318.5c8.5,0,15.4-6.9,15.4-15.4v-42.8
	C1295.1,220.6,1288.2,213.7,1279.7,213.7z M1120.5,214.6c-11.6,0-21-9.4-21-21c0-11.6,9.4-21,21-21c11.6,0,21,9.4,21,21
	C1141.5,205.2,1132.1,214.6,1120.5,214.6z"/>
<path class="et7" d="M961.3,287.4h318.5c8.5,0,15.4-6.9,15.4-15.4v-42.8L961.3,287.4z"/>
<g>
	<path class="et8" d="M110.5,1490.7c21.2-21.7,58.6-36.6,101.8-38.4v-63.9h-74.5c-1.7,0-3.1-1.4-4.1-3.7c-0.7-1.6-1-3.5-1-5.6v-3.2
		c0-5.1,2.3-9.3,5.1-9.3h74.5V1188c0-4.7,3.9-8.6,8.6-8.6l0,0c4.7,0,8.6,3.9,8.6,8.6v178.6H304c2.8,0,5.1,4.2,5.1,9.3v3.2
		c0,2.2-0.4,4.2-1.1,5.8c-0.9,2.1-2.4,3.5-4,3.5h-74.5v63.9c43.2,1.8,80.6,16.7,101.8,38.4c1.5,1.6,3,3.2,4.4,4.8H106.1
		C107.5,1493.8,109,1492.2,110.5,1490.7z"/>
	<path class="et9" d="M212.3,1452.6c30.1,2.8,56,17.3,71,38.1c1.1,1.6,2.2,3.2,3.2,4.8h21.2c-1-1.6-2.1-3.2-3.2-4.8
		c-16.7-23.1-47-38.5-81.7-38.5C219.4,1452.1,215.8,1452.3,212.3,1452.6z"/>
</g>
<path class="et10" d="M447.3,1452.9c0,0,2.6,41.9,10.7,42.5c1.1,0.1,41.1,0.1,41.1,0.1l51.1-0.3c0,0-0.3-12.3-15.3-14.9
	c-14.9-2.6-39.9-13.1-49-32.8C476.4,1426.7,447.3,1452.9,447.3,1452.9z"/>
<path class="et11" d="M245,1145.2c7.3,12.4,15.7,24,24.4,34.9c26.9,33.9,56.1,61.9,61.2,88.3c1,5.3,2.6,10.9,4.7,16.9
	c2.1,6.1,4.8,12.4,7.8,19c11.4,24.8,28.5,52.5,45.5,77.8c29.4,43.6,53.5,72.9,53.5,72.9s7.7,6,29.8,6c6.1,0,26.8-3.4,26.8-3.4
	c-2-5.1-26.4-63-56.1-136.6c-12-29.9-24.8-61.6-37.1-92.3c-1.6-4.1-3.3-8.2-4.9-12.2c-3.8-9.4-7.5-18.6-11.1-27.6
	c-27.8-69.1-50-124.1-50-124.1s-111.1-42.1-122.7-14.8C207.4,1072.5,215.1,1094.6,245,1145.2z"/>
<rect x="434.1" y="1029.7" class="et8" width="168.3" height="465.8"/>
<rect x="595.4" y="1029.7" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1311.1548 2525.1624)" class="et12" width="120.4" height="465.8"/>
<rect x="422.6" y="1020.3" class="et13" width="173.9" height="9.4"/>
<rect x="596.5" y="1020.3" class="et14" width="131.7" height="9.4"/>
<path class="et15" d="M420,957.6c0,0,24.1,13.7,30.9,13.7c6.8,0,33.3-5.1,40.6-1.3c7.4,3.8,20.8,19,23.3,23.9
	c4.5,8.9-18.2,5-28.9-2.6c-1.6-1.1-3.5-1.5-5.4-1.1c-5.5,1.2-17.1,3.3-22.7,1.2c-7.5-2.8-46.9-5.8-46.9-5.8L420,957.6z"/>
<path class="et16" d="M236.5,770.8c0,0,25,177.7,66.4,193.6c20.4,7.9,110.7,25.7,137.4,27.4c0,0,6.5-5.3,4.9-26.9
	c0,0-94.6-34.7-108-53.6c-20-28.1,12.4-114.8-12.7-141.2C299.5,743.8,257.2,732.4,236.5,770.8z"/>
<path class="et10" d="M329.8,1333.3c0,0-35.3,22.7-31.9,30.1c0.5,1,20,35.9,20,35.9l25.2,44.5c0,0,10.6-6.3,5.6-20.6
	c-5-14.3-8-41.2,4.8-58.8C366.9,1345.9,329.8,1333.3,329.8,1333.3z"/>
<path class="et11" d="M349.8,1376.8c0,0-24.8-23.1-28.7-37.4c0,0,90.9-108.9,110.6-133.3c0.9-1.1,0.7-2.4-0.5-3.7
	c-14.9-16.8-180.3-50.9-201.9-84.5c-7.5-11.6-11.7-22.6-14-32.2c-4.9-20.5-1-34.7-1-34.7l165.2-4.8c0,0,137.5,85.6,153.1,144
	C548.2,1248.6,349.8,1376.8,349.8,1376.8z"/>
<path class="et11" d="M431.2,1202.4c-14.9-16.8-180.3-50.9-201.9-84.5c-7.5-11.6-11.7-22.6-14-32.2v-20.9l70.2-11
	c0,0,19.9,61.3,78.9,84.2C419.6,1159.4,430.1,1197.6,431.2,1202.4z"/>
<path class="et17" d="M334.3,759.6c-33.4,8.6-88.8-22.3-88.8-22.3s21.5-14.5,29.5-46.2c0.4-1.6,0.8-3.2,1.1-4.9
	c3.5-0.7,10.1-2.1,17.2-3.6l25.5,22.9c0,0-0.6,9.5-3.8,25.8c-0.6,2.8-1.2,5.9-1.9,9.2C326.7,748.9,334.3,759.6,334.3,759.6z"/>
<path class="et16" d="M210.1,898.2c4.8,64.4-1.5,162.3,3.9,179.3c4.7,14.6,58.1,5.9,79.4,1c7.9-1.8,17.4-4.4,27.3-7.4
	c27.5-8.5,57.5-20.7,60.7-29.7c0.1-0.4,0.2-0.7,0.3-1c0.3-1.3,0.2-2.5-0.1-3.8c-1.1-4.6-5.2-9.6-5.7-21c-0.5-12.7-0.5-27.7-0.9-43.9
	c0-1.8-0.1-3.8-0.2-5.6l0-0.7c0-0.3,0-0.7,0-1c-0.5-18-1.4-36.9-2.5-55.6c-0.7-11.8-1.5-23.4-2.4-34.6c-2.4-28.8-5.6-54.5-9.9-70.7
	c-7-26.4-22.4-43.7-36.5-56.3l-10.2-8.7c-11.3,2.5-24.7,0.8-36.9-4.9c-13.1-6.2-17.7-10.7-19.9-9
	C226.2,747.8,204.4,819.4,210.1,898.2z"/>
<path class="et16" d="M320.7,1071c27.5-8.5,57.5-20.7,60.7-29.7c0.1-0.4,0.2-0.7,0.3-1c0-1.3,0-2.5-0.1-3.8c-1.1-4.6-5.2-9.6-5.7-21
	c-0.5-12.7-0.5-27.7-0.9-43.9c0-1.8-0.1-3.8-0.2-5.6l0-0.7c0-0.3,0-0.7,0-1L263.5,822.5C263.5,822.5,188.7,978.3,320.7,1071z"/>
<path class="et15" d="M318.7,705.4c0,0-0.6,9.5-3.8,25.8c-24.9-8.1-36.2-30.6-40-40.1c0.4-1.6,0.8-3.2,1.1-4.9
	c3.5-0.7,10.1-2.1,17.2-3.6L318.7,705.4z"/>
<path class="et17" d="M322.1,719.8c0,0-54.4-9.1-48.2-42.8c6.2-33.8,3.3-57.7,38.3-53.3c35,4.4,39.3,18.5,40,30.2
	C352.9,665.7,336.4,721.3,322.1,719.8z"/>
<polygon class="et18" points="441.1,1012 576.9,1012 613.8,892.7 618.6,894.2 579.6,1020.3 441.1,1020.3 "/>
<path class="et17" d="M405.1,1015.5c0,0,44.5,11.7,61.2,10.5c16.7-1.2,30.6-9.2,32.7-13.3c2.1-4.1,6.5-11.5-4.8-10.1
	c-11.4,1.4-26.9,3.8-27.3,1.6c-0.5-2.2,9.3-2,9.3-2s2.9-4.3-0.8-4.4c-3.7-0.2-14.5-3.1-18.5-1.1c-4,2-7.7,9.4-10.7,9.1
	c-2.9-0.3-37.2-15.4-37.2-15.4L405.1,1015.5z"/>
<path class="et16" d="M247.6,779.7c0,0,5.3,179.4,44.7,199.7c19.4,10.1,107.2,37.7,133.5,42.4c0,0,7.1-4.6,7.9-26.2
	c0,0-90.2-44.9-101.5-65.2c-16.8-30.1,25-112.7,3-141.7C313.1,759.7,272.4,743.8,247.6,779.7z"/>
<path class="et19" d="M274.9,691.1c0,0-0.6-11.6,6.5-18.2c0,0-2.5-11.6,2.1-13.5c4.6-1.9,5.6,7.9,7.7,6.3
	c2.1-1.5,11.5-8.8,15.5-19.5c4-10.7,40.8,2.3,45.1,4.2c4.3,1.9,18.1-5.2,20.8-10.9c0,0-7.7-6.1-11.1-10.7
	c-3.4-4.6-27.6-35.6-61.3-26.8c-33.7,8.8-38.3,24.9-36.4,38.7C265.8,654.4,261.1,686.5,274.9,691.1z"/>
<path class="et3" d="M337.5,1122L337.5,1122c-51.1,0-101.7-10.3-148.6-30.4l-30.5-13c-27.7-11.8-58.4,8.5-58.4,38.6v15.1v26.2v16.8
	c0,5.7,4.6,10.3,10.3,10.3h227.3c5.7,0,10.3-4.6,10.3-10.3v-43.1C347.8,1126.7,343.2,1122,337.5,1122z"/>
<g>
	<path class="et5" d="M1226,1166.9c-47.9,8.2-94.3,10-129.7,7.2c-35.7-2.8-56.6-13.8-58.7-26.4c-2-11.4,11.3-24.5,38.2-37.8l0,0
		c-25.8,12.8-38.4,27.1-36.6,37.5c2,11.5,22.8,19.6,57.2,22.3c35.2,2.8,80.9-0.2,128.7-8.4c47.8-8.2,91.8-20.6,124.1-35
		c31.5-14,48.4-28.6,46.4-40.1c-2.1-12.2-24.6-23.9-58.7-30.6c-38.8-7.6-84.5-5.1-129.6,2.7c-84.6,14.5-117.7,30.4-145.8,47.9l0,0
		c28.2-17.6,60.2-37.6,145.1-52.2c45.2-7.8,91.6-7.8,130.6-0.2c34.8,6.8,57.8,19.1,60,32.1c2.2,12.6-14.7,27.5-47.4,42.1
		C1317.4,1142.4,1273.9,1158.6,1226,1166.9z"/>
</g>
<path class="et17" d="M1783.6,1170c0,0-2.8,27-5.8,32.1c-3,5.1-16,21.4-21.5,24.6c-5.5,3.2,3.4-21.5,2.2-26.3
	c-1.1-4.8-4.5,8.8-7,9.1c-2.4,0.3-1.8-11.9-1.9-16.3c-0.1-4.4,11.8-15.4,16-24.9C1769.9,1158.8,1783.6,1170,1783.6,1170z"/>
<path class="et17" d="M1452.1,921.2l-6.2-11.7c0,0,3.1-20.8-23-34c0,0-9.5-3.9-14.9,3.9c-5,7.2,9.5,5.9,8.7,18.1
	c-0.8,12.3,5,19.5,13.2,23.5l6.7,7.8L1452.1,921.2z"/>
<path class="et16" d="M1699,893.2c0,0-22.4,110.1-145.9,146.1c-38.5,11.2-126.1-115.6-126.1-115.6s3.1-11.7,22.4-9
	c0,0,85.2,56.4,96,62.6c9.5,5.4,72.1-69.9,100.7-82.1C1674.6,882.9,1699,893.2,1699,893.2z"/>
<g>
	
		<rect x="1414.6" y="833.8" transform="matrix(0.1518 -0.9884 0.9884 0.1518 328.1977 2165.9285)" class="et20" width="23.1" height="116"/>
	<polygon class="et17" points="1342.1,878.8 1367,894.3 1370.5,871.5 	"/>
	<path class="et17" d="M1497.3,898.8l-1.1,7.2c-0.7,4.4-4.8,7.5-9.3,6.8l-5.3-0.8l3.5-22.8l5.8,0.9
		C1495.1,890.7,1498,894.6,1497.3,898.8z"/>
</g>
<path class="et19" d="M1846.4,1585.3c0,0,15.3,14,12.3,23.9c-3,9.9-16.8,16.5-18.7,28.7c-1.9,12.1-9.3,18.8-16.1,18.8
	c-6.7,0-32.1,0-32.1,0s-4.9-8.5,7-11.1c12-2.6,16.5-16.8,15.7-30.1c-0.7-13.2,1.1-30.9,1.1-30.9l25.4-5.9L1846.4,1585.3z"/>
<path class="et19" d="M1658.5,1621.4c0,0,2.7,31.8-4,35.1l-86-0.2c0,0,0-9.9,13.8-12.1c13.8-2.2,38.1-10.3,42.2-28.3
	C1628.6,1597.9,1658.5,1621.4,1658.5,1621.4z"/>
<path class="et11" d="M1585.9,1172.2c0,0,4.5,55.7,9.9,122.8c7.2,91,16,202.8,16.8,224c1.5,36.8,7.5,95.6,7.5,95.6s14.2,14,42.6,8.1
	c0,0,25.3-84,32.5-172.9c0.1-1.5,0.2-2.9,0.3-4.4c6.7-90.5,17.2-281,17.2-281L1585.9,1172.2z"/>
<path class="et11" d="M1585.9,1172.2c0,0,4.5,55.7,9.9,122.8l99.4,154.9c0.1-1.5,0.2-2.9,0.3-4.4c6.7-90.5,17.2-281,17.2-281
	L1585.9,1172.2z"/>
<path class="et11" d="M1606.6,1178.8c0,0,81.8,201.9,135.6,296.8c53.8,94.9,70.6,121.4,70.6,121.4s30.8-12.1,33.6-20.4
	c0,0-20.6-183.2-56.6-227.8c-20.4-25.2-44.3-100.9-45.4-127.4c-1.1-26.5-7.3-78.9-7.3-78.9L1606.6,1178.8z"/>
<path class="et17" d="M1714.2,867.6c0,0-55.3,26.7-87.2,16.8c0,0,7.9-9.9,21.6-17.3c-1.1-6.4-2-12-2.5-16.7c-1.3-10.9-1.3-17-1.3-17
	l21.4-16.8l20.2-16.1C1684.7,845.8,1714.2,867.6,1714.2,867.6z"/>
<path class="et16" d="M1743.4,1152.7c0.1,3.4-7.1,7.2-10.3,8.7c-23.7,10.4-45.8,17.6-70.8,20.1c-29.6,3-64.5-3.6-76.6-3.4
	c-1.2,0-2.3-0.5-3.3-1.3c-0.9-0.6-1.5-36.2-0.9-81.1c0-1.8,0-3.6,0.1-5.4v-0.6c0.2-17.5,0.9-35.9,1.7-54.3
	c1.7-40.4,4.9-79.3,10.3-100.8c6.5-25.4,21.2-42.2,34.6-54.4l11-9.7c11,2.2,22.7,1.6,34.4-4.1c12.8-6.2,26.5-12.8,28.7-11.1
	c16.7,12.2,28,39.6,34,74.7c3.4,20.2,5.2,42.8,5,66.5c0,8.7-0.2,17.6-0.7,26.5c-2.9,47.8,0,89.4,1.7,113.5
	C1742.9,1143.4,1743.2,1148.8,1743.4,1152.7z"/>
<path class="et16" d="M1742.4,1150.5c-193.8-0.4-35.7-210.3-35.7-210.3L1738,930c2.5,21.6,3.7,45.7,3.6,71c0,9.3-0.2,18.8-0.5,28.3
	C1739,1080.4,1741.1,1124.8,1742.4,1150.5z"/>
<path class="et21" d="M1686.7,817.9c0,0-14,25.5-40.6,32.6c-1.3-10.9-1.3-17-1.3-17l21.4-16.8L1686.7,817.9z"/>
<path class="et17" d="M1635,832.7c0,0,52.8,8.6,57.8-23.8c5-32.5,15.1-53.2-18.4-60.1c-33.5-6.9-42,4.5-46.3,15
	C1623.7,774.2,1621.4,829.6,1635,832.7z"/>
<path class="et22" d="M1711.9,744.9c0.5-0.5,1.6-1,4-1.1c0,0-2-1.2-4.6-1.3c-0.5-3.4-2.3-9.5-8.2-10.9c0,0,3.2,1.7,3.6,5.3
	c-6.8-7-21.4-12.1-61.9-8.9c-53.3,4.2-35.7,38.3-28.8,42.4c6.9,4.1,24.3-7.1,37.1-6.1c12.9,1,15.3,19.6,19.7,22.2
	c4.4,2.5,2.6-2,8.6-4.6c5.9-2.7,6.5,7.1,4.8,13.5c-1.7,6.4,6.4,14.8,6.4,14.8s0,0,8.2-8.8c8.2-8.8,15.9-46.2,12.2-54.2
	C1712.6,746.3,1712.3,745.6,1711.9,744.9z"/>
<path class="et16" d="M1702.4,855.1c0,0,33.4,15.8,49.4,40.1c24.8,37.7,53.6,175.2,54.2,187.9c0.5,12.7-21,102.6-21,102.6
	s-21.9-1.4-27.5-12.5c0,0,4.4-92.3,1.6-98.3c-2.8-5.9-91.1-123.9-81.2-149C1687.8,900.8,1702.4,855.1,1702.4,855.1z"/>
<path class="et17" d="M1445.8,908.9c0,0,1.8-7.2-3.2-10.4c-5-3.2-9.6-12-11.7-10.8c-4.3,2.5,2.4,10.1,1.1,19.7
	C1431.7,910.7,1439.5,919.5,1445.8,908.9z"/>
<ellipse class="et23" cx="1716" cy="1659.3" rx="253.7" ry="12.6"/>
<g>
	<path class="et1" d="M1599.1,451.1c-1.1-3.1,0.3-6.7,3-7.9c2.8-1.2,5.9,0.3,7,3.4c1.1,3.1-0.3,6.7-3,7.9S1600.2,454.2,1599.1,451.1
		z"/>
</g>
<g>
	<path class="et1" d="M1450.1,118.3c-1.1-3.1,0.3-6.7,3-7.9c2.8-1.2,5.9,0.3,7,3.4c1.1,3.1-0.3,6.7-3,7.9
		C1454.4,122.9,1451.2,121.4,1450.1,118.3z"/>
</g>
<g>
	<path class="et1" d="M614.4,948.9c-1.1-3.1,0.3-6.7,3-7.9c2.8-1.2,5.9,0.3,7,3.4c1.1,3.1-0.3,6.7-3,7.9
		C618.6,953.6,615.5,952.1,614.4,948.9z"/>
</g>
<g>
	<g>
		<path class="et1" d="M99.9,976.4c-2.5,0-4.5-2.1-4.5-4.6v-50.1c0-2.5,2-4.6,4.5-4.6c2.5,0,4.5,2.1,4.5,4.6v50.1
			C104.4,974.4,102.4,976.4,99.9,976.4z"/>
	</g>
	<g>
		<path class="et1" d="M124.5,951.4H75.4c-2.5,0-4.5-2.1-4.5-4.6s2-4.6,4.5-4.6h49.2c2.5,0,4.5,2.1,4.5,4.6S127,951.4,124.5,951.4z"
			/>
	</g>
</g>
<g>
	<g>
		<path class="et1" d="M1735.6,668.9c-1.4,0-2.6-1.3-2.6-2.9v-32.1c0-1.6,1.1-2.9,2.6-2.9c1.4,0,2.6,1.3,2.6,2.9V666
			C1738.2,667.6,1737.1,668.9,1735.6,668.9z"/>
	</g>
	<g>
		<path class="et1" d="M1749.6,652.9h-27.9c-1.4,0-2.6-1.3-2.6-2.9c0-1.6,1.1-2.9,2.6-2.9h27.9c1.4,0,2.6,1.3,2.6,2.9
			C1752.1,651.6,1751,652.9,1749.6,652.9z"/>
	</g>
</g>
<g>
	<g>
		<path class="et1" d="M519,649.6c-1.2,0-2.2-1.3-2.2-2.9v-31.5c0-1.6,1-2.9,2.2-2.9c1.2,0,2.2,1.3,2.2,2.9v31.5
			C521.1,648.3,520.2,649.6,519,649.6z"/>
	</g>
	<g>
		<path class="et1" d="M530.8,633.9h-23.6c-1.2,0-2.2-1.3-2.2-2.9c0-1.6,1-2.9,2.2-2.9h23.6c1.2,0,2.2,1.3,2.2,2.9
			C533,632.6,532,633.9,530.8,633.9z"/>
	</g>
</g>
<g>
	<path class="et1" d="M1950.2,1256.2c-4.7-10.7-16.8-16.5-26.9-12.9c-10.1,3.5-14.5,15-9.7,25.7c4.7,10.7,16.8,16.5,26.9,12.9
		C1950.6,1278.4,1954.9,1266.9,1950.2,1256.2z"/>
</g>
</svg>

</b-col>
    </b-row>
    
  </b-container>
</template>

<script>
  

  export default {
    computed: {
      
    },
    components: {
     
    },
    data() {
      return {}
    },
  }
</script>
<style>
.et0{fill:#EFEFFF;}
	.et1{fill:#B2AAF2;}
	.et2{fill:#D2D2FF;}
	.et3{fill:#5148A5;}
	.et4{fill:#FFFFFF;}
	.et5{fill:#B0AFFD;}
	.et6{fill:#FFB127;}
	.et7{fill:#ED7D2B;}
	.et8{fill:#D39E7A;}
	.et9{fill:#E0B594;}
	.et10{fill:#163560;}
	.et11{fill:#3D38A0;}
	.et12{fill:#F2C7AA;}
	.et13{fill:#AF7D5B;}
	.et14{fill:#E2B291;}
	.et15{fill:#ED975D;}
	.et16{fill:#FFC25B;}
	.et17{fill:#FFB27D;}
	.et18{fill:#1C468A;}
	.et19{fill:#233862;}
	.et20{fill:#C65447;}
	.et21{fill:#ED985F;}
	.et22{fill:#2C3A64;}
	.et23{opacity:0.4;fill:#B0AFFD;}
</style>