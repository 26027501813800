<template >
  <div>
    <b-row align-h="center" align="center"><b-col cols="11"><h5><b-icon icon="person-bounding-box"/> Sube una identificación vigente </h5><br></b-col></b-row>
    <b-row >      
      <b-col cols="12" sm="10" lg="6">
        <b-form-select size="md"
          v-model="tipoId"
          :options="optionsId"
          @input="resetForms"
        ></b-form-select><br><br>
      </b-col>
    </b-row>
    <b-row v-if="tipoId=='ine'">
      <b-col cols="12"><h5>Frente</h5></b-col>
      <b-col cols="12" v-if="idFrontB64!=null" sm="10" lg="7"><img style="margin-bottom:15px;" height="120px;" :src="'data:media/'+idFrontTipo+';base64,' + idFrontB64" /></b-col>
      <b-col cols="12" v-if="idFrontB64!=null"><b-button size="sm" variant="link"  @click="resetFormFront">Eliminar <b-icon icon="trash"/></b-button><br><br></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><img style="margin-bottom:15px;" height="60px;" :src="'../imgs/ids/ine-front.png'" /></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><b-form-file :state="false" placeholder="Sube un archivo" size="sm" v-model="fileFront"  @change="loadIdPerson" name="ine-front" id="ine-front"/><br><br></b-col>
      <b-col cols="12"><h5>Reverso</h5></b-col>
      <b-col cols="12" v-if="idBackB64!=null" sm="10" lg="7"><img style="margin-bottom:15px;" height="120px;" :src="'data:media/'+idBackTipo+';base64,' + idBackB64" /></b-col>
      <b-col cols="12" v-if="idBackB64!=null"><b-button size="sm" variant="link"  @click="resetFormBack">Eliminar <b-icon icon="trash"/></b-button><br><br></b-col>
      <b-col cols="12" v-if="idBackB64==null" sm="10" lg="7"><img style="margin-bottom:15px;" height="60px;" :src="'../imgs/ids/ine-back.png'" /></b-col>
      <b-col cols="12" v-if="idBackB64==null" sm="10" lg="7"><b-form-file :state="false" placeholder="Sube un archivo" size="sm"  v-model="fileBack" @change="loadIdPerson" name="ine-back" id="ine-back"/></b-col>
    </b-row>
    <b-row v-if="tipoId=='cedula'">
      <b-col cols="12"><h5>Frente</h5></b-col>
      <b-col cols="12" v-if="idFrontB64!=null" sm="10" lg="7"><img style="margin-bottom:15px;" height="120px;" :src="'data:media/'+idFrontTipo+';base64,' + idFrontB64" /></b-col>
      <b-col cols="12" v-if="idFrontB64!=null"><b-button size="sm" variant="link"  @click="resetFormFront">Eliminar <b-icon icon="trash"/></b-button><br><br></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><img style="margin-bottom:15px;" height="60px;" :src="'../imgs/ids/cedula-front.png'" /></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><b-form-file :state="false" placeholder="Sube un archivo" size="sm" v-model="fileFront"  @change="loadIdPerson" name="cedula-front" id="cedula-front"/><br><br></b-col>
      <b-col cols="12"><h5>Reverso</h5></b-col>
      <b-col cols="12" v-if="idBackB64!=null" sm="10" lg="7"><img style="margin-bottom:15px;" height="120px;" :src="'data:media/'+idBackTipo+';base64,' + idBackB64" /></b-col>
      <b-col cols="12" v-if="idBackB64!=null"><b-button size="sm" variant="link"  @click="resetFormBack">Eliminar <b-icon icon="trash"/></b-button><br><br></b-col>
      <b-col cols="12" v-if="idBackB64==null" sm="10" lg="7"><img style="margin-bottom:15px;" height="60px;" :src="'../imgs/ids/cedula-back.png'" /></b-col>
      <b-col cols="12" v-if="idBackB64==null" sm="10" lg="7"><b-form-file :state="false" placeholder="Sube un archivo" size="sm" v-model="fileBack"  @change="loadIdPerson" name="cedula-back" id="cedula-back"/></b-col>
    </b-row>
    <b-row v-if="tipoId=='pasaporte'">
      <b-col cols="12"><h5>Frente</h5></b-col>
      <b-col cols="12" v-if="idFrontB64!=null" sm="10" lg="7"><img style="margin-bottom:15px;" height="120px;" :src="'data:media/'+idFrontTipo+';base64,' + idFrontB64" /></b-col>
      <b-col cols="12" v-if="idFrontB64!=null"><b-button size="sm" variant="link"  @click="resetFormFront">Eliminar <b-icon icon="trash"/></b-button><br><br></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><img style="margin-bottom:15px;" height="60px;" :src="'../imgs/ids/pasaporte.png'" /></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><b-form-file :state="false" placeholder="Sube un archivo" size="sm" v-model="fileFront"  @change="loadIdPerson" name="pasaporte" id="pasaporte"/></b-col>
    </b-row>
    <b-row v-if="tipoId=='escolar'">
      <b-col cols="12"><h5>Frente</h5></b-col>
      <b-col cols="12" v-if="idFrontB64!=null" sm="10" lg="7"><img style="margin-bottom:15px;" height="120px;" :src="'data:media/'+idFrontTipo+';base64,' + idFrontB64" /></b-col>
      <b-col cols="12" v-if="idFrontB64!=null"><b-button size="sm" variant="link"  @click="resetFormFront">Eliminar <b-icon icon="trash"/></b-button><br><br></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><img style="margin-bottom:15px;" height="60px;" :src="'../imgs/ids/escolar.png'" /></b-col>
      <b-col cols="12" v-if="idFrontB64==null" sm="10" lg="7"><b-form-file :state="false" placeholder="Sube un archivo" size="sm" v-model="fileFront"  @change="loadIdPerson" name="escolar" id="escolar"/></b-col>
    </b-row>
    
    <b-row align-h="center" align="center"> 
      <b-col cols="11" sm="9" md="8" lg="6" xl="5" style="margin-top:10px; ">
        <br><br>
        <b-button :disabled="!isIdPersonCompleta" class="btn-back-purple" pill size="lg" block @click="enviarSolicitudSinFirma()" variant="info">Continuar <b-icon  icon="arrow-right-square" ></b-icon></b-button>        
        <br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapState} from 'vuex';
  import {enviarSolicitudFirma} from '@/axios-tools'
  
  export default {
    computed: {
      ...mapState({
        perfil: state => state.user.perfil,
        token: state => state.user.token,
        currentService: state => state.user.currentService,
      }),
      isIdPersonCompleta: function(){
        if(this.tipoId=='ine'||this.tipoId=='cedula'){
          return this.idFrontB64!=null && this.idBackB64!=null && (this.idFrontTipo==this.idBackTipo)
        }else{
          return this.idFrontB64!=null
        }
      }
    },
    props:[],
    created() {
     
    },
    components: {
      
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        tipoId:"ine",
        optionsId: [
          { text: 'Credencial para votar', value: 'ine' },
          { text: 'Cédula Profresional', value: 'cedula' },
          { text: 'Pasaporte', value: 'pasaporte' },
          { text: 'Credencial Escolar (Solo menores de edad)', value: 'escolar' }
        ],
        idFrontB64:null,
        idFrontTipo:null,
        idBackB64:null,
        idBackTipo:null,
        fileFront:null,
        fileBack:null,
      }
    },
    mounted() {
        
    },
    methods: { 
      enviarSolicitudSinFirma(){
        var firmaDto ={
          certificado: null,
          selloDigitalContrib:null,
          hashCadenaOriginal:this.currentService.hashSolicitud,
        }
        var idDto={
          b64Front : this.idFrontB64,
          b64Back: this.idBackB64,
          tipoId:this.id,
          extension:this.idFrontTipo
        }
        enviarSolicitudFirma(this.token, this.currentService.idUserService, firmaDto, idDto, this.$store, this.$router);
        this.$store.commit("updateIsShowModalFirmaElectronicaSimple", false);
        this.$store.commit("updateIsShowModalFirmaElectronica", false);
        this.resetForms();
      },
      resetForms(){
        this.idFrontB64=null
        this.idFrontTipo=null
        this.idBackB64=null
        this.idBackTipo=null
        this.fileFront=null
        this.fileBack=null
      },
      resetFormFront(){
        this.idFrontB64=null
        this.idFrontTipo=null
        this.fileFront=null
      },
      resetFormBack(){
        this.idBackB64=null
        this.idBackTipo=null
        this.fileBack=null
      },
      loadIdPerson(evt){
        //this.$store.commit("updateIsWorkingAdmin", true);
        var f = evt.target.files[0];
        var reader = new FileReader();
        var id = evt.target.id;
        //Creamos una refrencia local al objeto this para poder acceder desde las funciones anidadas a los objetos cliente, token .. etc
        var thisObject = this;
        reader.onload = (function() {
            return function(theFile) {
            var binaryData = theFile.target.result;
            // Convertir datos binarios a base 64
            var base64String = window.btoa(binaryData);
            // mostrando el archivo convertido a base64
            var tipo = f.name.split('.').pop();
            tipo = tipo.toLowerCase();
            if( tipo == 'jpg' || tipo == 'png' || tipo == 'jpeg'){
              if(f.size <= 3000000){
                if(id=='ine-front'||id=='cedula-front'||id=='pasaporte'||id=='escolar'){ thisObject.idFrontB64=base64String; thisObject.idFrontTipo=tipo; }
                else{ thisObject.idBackB64=base64String; thisObject.idBackTipo=tipo; }
              }else{
                thisObject.msgErrorFile="El documento no debe exceder 3mb de tamaño";
                thisObject.isShowModalErrorFile=true;
              }
            }else{
              thisObject.msgErrorFile="La extensión del archivo no es válida. Sube un archivo jpg, jpeg o png";
              thisObject.isShowModalErrorFile=true;
            }            
          };
        })(f);
        reader.onerror = function(error){
          alert("error al cargar archivo "+error);
        };
        // Leer en el archivo de imagen como una URL de datos.
        reader.readAsBinaryString(f);
      },
    },
    validations: {
    }
  }
</script>
<style>
</style>